import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from './../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadFormField } from '../common/RadFormField'
import { RadButton } from '../common/RadButton'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { stateList } from '../common/stateList'

export function OrganizationEdit () {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { data: organization } = useGet(organizationId ? `/api/organization/${organizationId}` : null)
  const [formValues, setFormValues] = useState()
  const { data: parentOptions } = useGet(`/api/option/organization?required=false&${organizationId !== undefined ? '&exclude=' + organizationId : ''}`)
  const { data: organizationTypeOptions } = useGet('/api/option/type?entity=organizationType')
  const { data: organizationRoleOptions } = useGet('/api/option/type?entity=organizationRole')
  const { data: organizationTeamSizeOptions } = useGet('/api/option/type?entity=organizationTeamSize')
  const { data: organizationDomainOptions } = useGet('/api/option/type?entity=organizationDomain')
  const { data: personOptions } = useGet('/api/option/person')
  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: null, label: '-' })

  useEffect(() => {
    const defaultFormValues = { contacts: [] }
    setFormValues(organization ?? defaultFormValues)
  }, [organization])

  const create = usePost('/api/organization', formValues, (resp) => { navigate(`/organization/${resp.id}`) })
  const update = usePut(`/api/organization/${organizationId}`, formValues, (resp) => { navigate(`/organization/${organizationId}`) })

  async function saveChanges () {
    if (organizationId) { update() } else { create() }
  }

  async function cancel () {
    if (organizationId) {
      navigate(`/organization/${organizationId}`)
    } else {
      navigate('/organization')
    }
  }

  if (
    formValues != null &&
    parentOptions != null &&
    organizationTypeOptions != null &&
    organizationRoleOptions != null &&
    organizationTeamSizeOptions != null &&
    organizationDomainOptions != null &&
    personOptions != null
  ) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id ? 'Edit Organization' : 'New Organization'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Parent Organization' field='parentId' optional>
                      <RadSelect
                        filterType='auto'
                        selectedOption={formValues.parentId ? parentOptions.find(x => x.value === formValues.parentId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, parentId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, parentId: null })
                          }
                        }}
                        options={parentOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Common Name' field='commonName'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.commonName ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, commonName: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Full Name' field='fullName'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.fullName ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, fullName: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Acronym' field='acronym'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.acronym ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, acronym: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Type' field='typeId'>
                      <RadSelect
                        selectedOption={formValues.typeId ? organizationTypeOptions.find(x => x.value === formValues.typeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, typeId: null })
                          }
                        }}
                        options={organizationTypeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Annual Revenue' field='annualRevenue' optional>
                      <RadInput
                        placeholder='Enter annual revenue'
                        ariaRequired
                        value={formValues.annualRevenue ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, annualRevenue: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Location' field='location' optional>
                      <RadInput
                        placeholder='Enter location'
                        ariaRequired
                        value={formValues.location ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, location: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Role' field='roleId' optional>
                      <RadSelect
                        selectedOption={formValues.roleId ? organizationRoleOptions.find(x => x.value === formValues.roleId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, roleId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, roleId: null })
                          }
                        }}
                        options={organizationRoleOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Team Size' field='teamSizeId' optional>
                      <RadSelect
                        selectedOption={formValues.teamSizeId ? organizationTeamSizeOptions.find(x => x.value === formValues.teamSizeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, teamSizeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, teamSizeId: null })
                          }
                        }}
                        options={organizationTeamSizeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Domain' field='domainId' optional>
                      <RadSelect
                        selectedOption={formValues.domainId ? organizationDomainOptions.find(x => x.value === formValues.domainId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, domainId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, domainId: null })
                          }
                        }}
                        options={organizationDomainOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Contacts</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const contacts = formValues.contacts
                      contacts.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, contacts })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const contacts = [...formValues.contacts]
                      contacts.splice(itemIndex, 1)
                      setFormValues({ ...formValues, contacts })
                    }}
                    items={formValues.contacts}
                    addButtonText='Add new contact'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = personOptions.filter((x) => !formValues.contacts.map((y) => y.personId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`contact.${item.id ?? item.uuid}.personId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={personOptions.find(x => x.value === item.personId?.toString())}
                                onChange={({ detail }) => {
                                  const contacts = formValues.contacts
                                  item.personId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, contacts })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a person'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Title',
                        control: item => {
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.title`}>
                              <RadInput
                                placeholder='Enter title'
                                ariaRequired
                                value={item.title}
                                onChange={({ detail }) => {
                                  const contacts = formValues.contacts
                                  item.title = detail.value
                                  setFormValues({ ...formValues, contacts })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Primary Employer',
                        control: item => {
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.primaryEmployer`}>
                              <RadCheckbox
                                checked={item.primaryEmployer}
                                onChange={({ detail }) => {
                                  const contacts = formValues.contacts
                                  item.primaryEmployer = detail.checked
                                  setFormValues({ ...formValues, contacts })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No contacts added to this organization.'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
