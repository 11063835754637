import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadMultiselectFilter } from '../common/RadMultiselectFilter'
import { createCommaSeparatedListClients,formatCurrency } from '../common/utilities'

export function ProjectList () {
  const { data: projectStatusOptions } = useGet('/api/option/type?entity=projectStatus&required')
  const { data: projectTypeOptions } = useGet('/api/option/type?entity=projectType&required')

  if (
    projectStatusOptions != null &&
    projectTypeOptions != null
  ) {
    return (
      <RadAdaptiveList
        entity='project'
        filters={
          <>
            <RadMultiselectFilter styles={{ flexGrow: 0 }} name='status' param='statusIds' options={projectStatusOptions} />
            <RadMultiselectFilter styles={{ flexGrow: 0 }} name='type' param='typeIds' options={projectTypeOptions} />
          </>
        }
        fields={[
          { header: 'Number', name: 'number', link: true },
          { header: 'Name', name: 'name', link: true },
          'client.commonName',
          'projectStatus.name',
          'projectType.name',
          { header: 'Final Budget', content: (item) => formatCurrency(item.finalBudget)}
        ]}
      />
    )
  }
}
