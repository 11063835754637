import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function ProjectEdit() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { projectId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: project } = useGet(projectId ? `/api/project/${projectId}` : null)
  const { data: complianceOptions } = useGet('/api/option/type?entity=complianceType&required')
  const { data: projectStatusOptions } = useGet('/api/option/type?entity=projectStatus')
  const { data: projectTypeOptions } = useGet('/api/option/type?entity=projectType')
  const { data: personOptions } = useGet('/api/option/person')
  const { data: organizations } = useGet('/api/option/organization?required')

  useEffect(() => {
    const defaultFormValues = { dataMigration: false, compliance: [], team: [] }
    setFormValues(project ?? defaultFormValues)
  }, [project])

  const create = usePost('/api/project', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/project/${resp.id}`) })
  const update = usePut(`/api/project/${projectId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/project/${projectId}`) })

  async function saveChanges() {
    if (projectId) { update() } else { create() }
  }

  async function cancel() {
    if (projectId) {
      navigate(searchParams.get('redirectURL') ?? `/project/${projectId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/project')
    }
  }

  if (
    formValues != null &&
    complianceOptions != null &&
    projectStatusOptions != null &&
    projectTypeOptions != null &&
    personOptions != null &&
    organizations != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
              </RadSpaceBetween>
            }
          >
            {formValues.id ? 'Edit Project' : 'New Project'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='ID' field='number' optional>
                      <RadInput
                        placeholder='Enter ID'
                        value={formValues.number ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, number: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Name' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Client' field='clientId'>
                      <RadSelect
                        filterType='auto'
                        selectedOption={formValues.clientId ? organizations.find(x => x.value === formValues.clientId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, clientId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, clientId: null })
                          }
                        }}
                        options={organizations}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Project Type' field='projectTypeId' optional>
                      <RadSelect
                        filterType='auto'
                        selectedOption={formValues.projectTypeId ? projectTypeOptions.find(x => x.value === formValues.projectTypeId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, projectTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, projectTypeId: null })
                          }
                        }}
                        options={projectTypeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Project Status' field='projectStatusId' optional>
                      <RadSelect
                        filterType='auto'
                        selectedOption={formValues.projectStatusId ? projectStatusOptions.find(x => x.value === formValues.projectStatusId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, projectStatusId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, projectStatusId: null })
                          }
                        }}
                        options={projectStatusOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Float Id' field='floatId'>
                      <RadInput
                        placeholder='Enter Float ID'
                        ariaRequired
                        value={formValues.floatId ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, floatId: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Post Project Data
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Goal' field='goal'>
                      <RadTextarea
                        placeholder='Enter goal'
                        ariaRequired
                        value={formValues.goal ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, goal: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Solution' field='solution'>
                      <RadTextarea
                        placeholder='Enter solution'
                        ariaRequired
                        value={formValues.solution ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, solution: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Key Lessons Learned' field='keyLessonsLearned'>
                      <RadTextarea
                        placeholder='Enter lessons learned'
                        ariaRequired
                        value={formValues.keyLessonsLearned ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, keyLessonsLearned: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Tooling' field='tooling'>
                      <RadTextarea
                        placeholder='Enter tooling'
                        ariaRequired
                        value={formValues.tooling ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, tooling: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Integrations' field='integrations'>
                      <RadTextarea
                        placeholder='Enter integrations'
                        ariaRequired
                        value={formValues.integrations ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, integrations: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Compliance' field='compliance'>
                      <RadSpaceBetween direction='horizontal' size='m'>
                        {complianceOptions.map(x =>
                          <RadCheckbox
                            key={x.label}
                            onChange={({ detail }) => {
                              const compliance = formValues.compliance
                              if (detail.checked) {
                                compliance.push({ name: x.label })
                              } else {
                                compliance.splice(compliance.findIndex(y => y.name === x.label), 1)
                              }
                              setFormValues({ ...formValues, compliance })
                            }}
                            checked={formValues.compliance.map(x => x.name).includes(x.label)}
                          >
                            {x.label}
                          </RadCheckbox>
                        )}
                      </RadSpaceBetween>
                    </RadFormField>
                    <RadFormField label='Data Migration' field='dataMigration'>
                      <RadCheckbox
                        onChange={({ detail }) => setFormValues({ ...formValues, dataMigration: detail.checked })}
                        checked={formValues.dataMigration}
                      >
                        Yes
                      </RadCheckbox>
                    </RadFormField>
                    <RadFormField label='Planned Start Date' field='plannedStartDate'>
                      <RadInput
                        type='date'
                        value={formValues.plannedStartDate}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, plannedStartDate: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Planned End Date' field='plannedEndDate'>
                      <RadInput
                        type='date'
                        value={formValues.plannedEndDate}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, plannedEndDate: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Actual Start Date' field='actualStartDate'>
                      <RadInput
                        type='date'
                        value={formValues.actualStartDate}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, actualStartDate: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Actual End Date' field='actualEndDate'>
                      <RadInput
                        type='date'
                        value={formValues.actualEndDate}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, actualEndDate: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Project Rate' field='projectRate'>
                      <RadInput
                        placeholder='Enter project rate'
                        type="number"
                        value={formValues.projectRate}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, projectRate: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Initial Budget' field='initialBudget'>
                      <RadInput
                        placeholder='Enter initial budget'
                        type="number"
                        value={formValues.initialBudget}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, initialBudget: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Final Budget' field='finalBudget'>
                      <RadInput
                        placeholder='Enter final budget'
                        type="number"
                        value={formValues.finalBudget}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, finalBudget: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Logged Hours' field='loggedHours'>
                      <RadInput
                        placeholder='Enter logged hours'
                        type="number"
                        value={formValues.loggedHours}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, loggedHours: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Billable Hours' field='billableHours'>
                      <RadInput
                        placeholder='Enter billable hours'
                        type="number"
                        value={formValues.billableHours}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, billableHours: detail.value })
                        }}
                      />
                    </RadFormField>
                    <RadFormField label='Non Billable Hours' field='nonBillableHours'>
                      <RadInput
                        placeholder='Enter non billable hours'
                        type="number"
                        value={formValues.nonBillableHours}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, nonBillableHours: detail.value })
                        }}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Team</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const team = formValues.team
                      team.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, team })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const team = [...formValues.team]
                      team.splice(itemIndex, 1)
                      setFormValues({ ...formValues, team })
                    }}
                    items={formValues.team}
                    addButtonText='Add new team member'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = personOptions.filter((x) => !formValues.team.map((y) => y.personId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`team.${item.id ?? item.uuid}.personId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={personOptions.find(x => x.value === item.personId?.toString())}
                                onChange={({ detail }) => {
                                  const team = formValues.team
                                  item.personId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, team })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a person'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Role',
                        control: item => {
                          return (
                            <RadFormField field={`team.${item.id ?? item.uuid}.role`}>
                              <RadInput
                                placeholder='Enter role'
                                ariaRequired
                                value={item.role}
                                onChange={({ detail }) => {
                                  const team = formValues.team
                                  item.role = detail.value
                                  setFormValues({ ...formValues, team })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No team added to this project.'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
