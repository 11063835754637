import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadCards } from '../common/RadCards'
import { RadLink } from '../common/RadLink'
import { toTitleCase } from '../common/utilities'

export function TypeList () {
  const entities = [
    'changeOrderStatus',
    'chartOfAccount',
    'complianceType',
    'contractStatus',
    'contractType',
    'invoiceStatus',
    'milestoneType',
    'organizationDomain',
    'organizationRole',
    'organizationTeamSize',
    'organizationType',
    'projectStatus',
    'projectType',
    'revenueDesignationType',
    'serviceStatus',
    'serviceType',
    'systemAccountType',
    'systemStatus',
    'systemInfrastructureType',
    'systemType',
    'supportType'
  ]
  const { data: types } = useGet('/api/type')

  if (types) {
    return (
      { type: 'link', text: 'Roles', href: '/t' },
        <RadAppLayout
          contentHeader={
            <RadHeader
              variant='h1'
            >
              Types
            </RadHeader>
        }
          content={
            <RadCards
              cardDefinition={{
                header: item => (
                  <RadLink fontSize='heading-m' href={`/type/${item}`}>{toTitleCase(item)}</RadLink>
                ),
                sections: [
                  {
                    id: 'types',
                    content: item => types.length > 0
                      ? <>{types.filter((type) => type.entity === item).map((type) => { return (<div key={type.id}>{type.name}</div>) })}</>
                      : <div>-</div>
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={entities}
              variant='full-page'
            />
        }
        />
    )
  }
}
