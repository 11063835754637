import React from 'react'

export function RadDivider ({ noPadding = false }) {
  return (
    <hr style={{
      paddingBottom: !noPadding ? 20 : 0,
      border: 'none',
      borderTop: '2px solid #E9EBED',
      color: '#000716',
      margin: 0
    }}
    />
  )
}
