import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadMultiselectFilter } from '../common/RadMultiselectFilter'
import { RadLink } from '../common/RadLink'
import { formatCurrency } from '../common/utilities'

export function InvoiceList () {
  const { data: statusOptions } = useGet('/api/option/type?entity=invoiceStatus&required')

  if (statusOptions != null) {
    return (
      <RadAdaptiveList
        entity='invoice'
        filters={
          <RadMultiselectFilter styles={{ flexGrow: 0 }} name='status' param='statusIds' options={statusOptions} />
        }
        showActions={false}
        fields={[
          'title',
          'dateRequested',
          'dateToBeSent',
          { header: 'Total', content: (item) => formatCurrency(item.total)},
          { header: 'Contract', content: (item) => <RadLink href={`/contract/${item.contract.id}`}>{item.contract.name}</RadLink>},
          { header: 'Client', content: (item) => <RadLink href={`/organization/${item.contract.client.id}`}>{item.contract.client.commonName}</RadLink>},
          { header: 'Funder', content: (item) => <RadLink href={`/organization/${item.contract.funder.id}`}>{item.contract.funder.commonName}</RadLink>},
          'invoiceStatus.name'
        ]}
      />
    )
  }
}
