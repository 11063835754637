import React from 'react'
import { useGet } from './hooks/useGet'
import { useIsMobile } from './hooks/useIsMobile'
import { RadAppLayout } from './common/RadAppLayout'
import { RadHeader } from './common/RadHeader'
import { RadPieChart } from './common/RadPieChart'
import { RadContainer } from './common/RadContainer'
import { RadColumnLayout } from './common/RadColumnLayout'
import { RadBox } from './common/RadBox'
import { RadSpaceBetween } from './common/RadSpaceBetween'
import { formatCurrency } from './common/utilities'
import { Box, ColumnLayout, SpaceBetween } from '@cloudscape-design/components'

export function Home () {
  const isMobile = useIsMobile()
  const { data: contractsStatus } = useGet('/api/home/contracts-by-status')
  const { data: projectStatus } = useGet('/api/home/project-by-status')
  const { data: activeSupport } = useGet('/api/home/active-support-contracts')
  const { data: invoicesByMonth } = useGet('/api/home/invoices-by-month')
  const { data: activeServiceByServiceLine } = useGet('/api/home/active-services-by-serviceline')

  const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant='awsui-key-label'>{label}</Box>
      <div>{children}</div>
    </div>
  )

  if (
    contractsStatus != null &&
    projectStatus != null &&
    activeSupport != null &&
    invoicesByMonth != null &&
    activeServiceByServiceLine != null
  ) {
    const contractsStatusInnerMetricValue = contractsStatus
      ? contractsStatus.reduce((total, item) => total + Number(item.value), 0)
      : 0
    const projectStatusInnerMetricValue = projectStatus
      ? projectStatus.reduce((total, item) => total + Number(item.value), 0)
      : 0
    const activeServicesInnerMetricValue = activeServiceByServiceLine
      ? activeServiceByServiceLine.reduce((total, item) => total + Number(item.value), 0)
      : 0
    const activeServicesProcessedData = activeServiceByServiceLine.map(item => ({
      title: item.title || 'Unknown',
      value: item.value
    }))

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
          >
            Home
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='s'>
            <RadColumnLayout columns={3}>
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    Contracts by Status
                  </RadHeader>
                }
                variant='stacked'
              >
                <RadPieChart
                  data={contractsStatus}
                  variant='donut'
                  segmentDescription={(datum, sum) => `${datum.value}`}
                  // segmentDescription={(datum, sum) => `${datum.value} | ${((datum.value / sum) * 100).toFixed(0)}%`}
                  i18nStrings={{
                    detailsValue: 'Count',
                    detailsPercentage: 'Percentage',
                    filterLabel: 'Filter displayed data',
                    filterPlaceholder: 'Filter data',
                    filterSelectedAriaLabel: 'selected',
                    detailPopoverDismissAriaLabel: 'Dismiss',
                    legendAriaLabel: 'Legend',
                    chartAriaRoleDescription: 'pie chart',
                    segmentAriaRoleDescription: 'segment'
                  }}
                  // ariaDescription='Pie chart showing how many cases per indicator.'
                  ariaLabel='Pie chart'
                  innerMetricDescription='total'
                  innerMetricValue={contractsStatusInnerMetricValue}
                  hideFilter
                  hideLegend
                  size={isMobile ? 'small' : 'large'}
                  empty={
                    <RadBox textAlign='center' color='inherit'>
                      Empty
                    </RadBox>
                  }
                  noMatch={
                    <RadBox textAlign='center' color='inherit'>
                      No match
                    </RadBox>
                  }
                />
              </RadContainer>
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    Project by Status
                  </RadHeader>
                }
                variant='stacked'
              >
                <RadPieChart
                  data={projectStatus}
                  variant='donut'
                  segmentDescription={(datum, sum) => `${datum.value}`}
                  // segmentDescription={(datum, sum) => `${datum.value} | ${((datum.value / sum) * 100).toFixed(0)}%`}
                  i18nStrings={{
                    detailsValue: 'Count',
                    detailsPercentage: 'Percentage',
                    filterLabel: 'Filter displayed data',
                    filterPlaceholder: 'Filter data',
                    filterSelectedAriaLabel: 'selected',
                    detailPopoverDismissAriaLabel: 'Dismiss',
                    legendAriaLabel: 'Legend',
                    chartAriaRoleDescription: 'pie chart',
                    segmentAriaRoleDescription: 'segment'
                  }}
                  // ariaDescription='Pie chart showing how many cases per indicator.'
                  ariaLabel='Pie chart'
                  innerMetricDescription='total'
                  innerMetricValue={projectStatusInnerMetricValue}
                  hideFilter
                  hideLegend
                  size={isMobile ? 'small' : 'large'}
                  empty={
                    <RadBox textAlign='center' color='inherit'>
                      Empty
                    </RadBox>
                  }
                  noMatch={
                    <RadBox textAlign='center' color='inherit'>
                      No match
                    </RadBox>
                  }
                />
              </RadContainer>
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    Services By Service Line
                  </RadHeader>
                }
                variant='stacked'
              >
                <RadPieChart
                  data={activeServicesProcessedData}
                  variant='donut'
                  segmentDescription={(datum, sum) => `${datum.value}`}
                  // segmentDescription={(datum, sum) => `${datum.value} | ${((datum.value / sum) * 100).toFixed(0)}%`}
                  i18nStrings={{
                    detailsValue: 'Count',
                    detailsPercentage: 'Percentage',
                    filterLabel: 'Filter displayed data',
                    filterPlaceholder: 'Filter data',
                    filterSelectedAriaLabel: 'selected',
                    detailPopoverDismissAriaLabel: 'Dismiss',
                    legendAriaLabel: 'Legend',
                    chartAriaRoleDescription: 'pie chart',
                    segmentAriaRoleDescription: 'segment'
                  }}
                  // ariaDescription='Pie chart showing how many cases per indicator.'
                  ariaLabel='Pie chart'
                  innerMetricDescription='total'
                  innerMetricValue={activeServicesInnerMetricValue}
                  hideFilter
                  hideLegend
                  size={isMobile ? 'small' : 'large'}
                  empty={
                    <RadBox textAlign='center' color='inherit'>
                      Empty
                    </RadBox>
                  }
                  noMatch={
                    <RadBox textAlign='center' color='inherit'>
                      No match
                    </RadBox>
                  }
                />
              </RadContainer>
            </RadColumnLayout>
            <RadColumnLayout columns={2}>
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    Active Support Contracts
                  </RadHeader>
                }
                variant='stacked'
              >
                <ColumnLayout columns={3} variant='text-grid'>
                  {activeSupport.map((item, index) => (
                    <SpaceBetween size='l' key={index}>
                      <ValueWithLabel label={item.service_type}>{item.count}</ValueWithLabel>
                    </SpaceBetween>
                  ))}
                </ColumnLayout>
              </RadContainer>
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    Invoices To Be Sent
                  </RadHeader>
                }
                variant='stacked'
              >
                <ColumnLayout columns={3} variant='text-grid'>
                  {Object.entries(invoicesByMonth[0]).map(([key, value], index) => (
                    <SpaceBetween size='l' key={index}>
                      <ValueWithLabel label={key}>{formatCurrency(value)}</ValueWithLabel>
                    </SpaceBetween>
                  ))}
                </ColumnLayout>
              </RadContainer>
            </RadColumnLayout>
          </RadSpaceBetween>
        }
      />
    )
  }
}
