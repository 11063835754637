import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadMultiselectFilter } from '../common/RadMultiselectFilter'
import { RadLink } from '../common/RadLink'

export function ContractServiceMilestoneList () {
  const { data: statusOptions } = useGet('/api/option/type?entity=milestoneType&required')

  if (statusOptions != null) {
    return (
      <RadAdaptiveList
        entity='contract-service-milestone'
        filters={
          <RadMultiselectFilter styles={{ flexGrow: 0 }} name='Type' param='statusIds' options={statusOptions} />
        }
        showActions={false}
        fields={[
           'name',
           'type.name',
           'value',
           { header: 'Contract', content: (item) => <RadLink href={`/contract/${item.contractService.contract.id}`}>{item.contractService.contract.name}</RadLink>},
          { header: 'Client', content: (item) => <RadLink href={`/organization/${item.contractService.contract.client.id}`}>{item.contractService.contract.client.commonName}</RadLink>},
          { header: 'Funder', content: (item) => <RadLink href={`/organization/${item.contractService.contract.funder.id}`}>{item.contractService.contract.funder.commonName}</RadLink>},
          // 'invoiceStatus.name'
        ]}
      />
    )
  }
}
