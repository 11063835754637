import React, { } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { formatDate } from '../common/utilities'

export function SystemDetail() {
  const navigate = useNavigate()
  const { systemId } = useParams()
  const { data: system } = useGet(`/api/system/${systemId}`)
  const remove = useDelete(`/api/system/${systemId}`, () => { navigate('/system') })
  const confirmDelete = useConfirm(
    'Delete system?',
    'Delete system permanently? This action cannot be undone.',
    'Delete',
    () => { remove() }
  )

  function edit(evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (
    system != null
  ) {
    return (
      <RadAppLayout
        name={system.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={system.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={system.description}
          >
            {system.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <Details system={system} />
            <Environments system={system} />
            <Infrastructures system={system} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Details({ system }) {
  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
        >
          Details
        </RadHeader>
      }
    >
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            ID
          </RadBox>
          <div>{system.id}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Name
          </RadBox>
          <div>{system.name}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Client
          </RadBox>
          <div>
            <RadLink href={`/organization/${system.client.id}`}>{system.client?.commonName ?? '-'}</RadLink>
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Type
          </RadBox>
          <div>{system.type?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Date First Launched
          </RadBox>
          <div>{formatDate(system.dateFirstLaunched)}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Status
          </RadBox>
          <div>{system.status?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Decommission Date
          </RadBox>
          <div>{system.decommissionDate != null ? formatDate(system.decommissionDate) : '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Account Type
          </RadBox>
          <div>{system.systemAccountType?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Is Contract Current?
          </RadBox>
          <div>
            {(() => {
              const status = system.isContractCurrent;
              return status === true ? 'Yes' : status === false ? 'No' : '-';
            })()}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Is Reimbursed?
          </RadBox>
          <div>
            {(() => {
              const status = system.isReimbursed;
              return status === true ? 'Yes' : status === false ? 'No' : '-';
            })()}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Is Marked Up?
          </RadBox>
          <div>
            {(() => {
              const status = system.isMarkedUp;
              return status === true ? 'Yes' : status === false ? 'No' : '-';
            })()}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Is Licensed?
          </RadBox>
          <div>
            {(() => {
              const status = system.isLicensed;
              return status === true ? 'Yes' : status === false ? 'No' : '-';
            })()}
          </div>
        </div>
      </RadColumnLayout>
    </RadContainer >
  )
}

function Environments({ system }) {
  return (
    <RadSpaceBetween size='s'>
      <RadHeader
        counter={'(' + system.environments.length + ')'}
        variant='h2'
      >
        Environments
        {system.environments.length === 0 &&
          <RadBox color='text-status-inactive' padding={{ top: 'xxs' }}>No environments added to this system.</RadBox>}
      </RadHeader>
      <RadCards
        cardDefinition={{
          header: item => item.name,
          sections: [
            {
              id: 'url',
              header: 'URL',
              content: item => item.url ? <RadLink href={item.url}>{item.url}</RadLink> : '-'
            },
            {
              id: 'status',
              header: 'Status',
              content: item => item.status.name
            },
            {
              id: 'description',
              header: 'Description',
              content: item => item.description ?? '-'
            }
          ]
        }}
        cardsPerRow={[
          { cards: 1 },
          { minWidth: 640, cards: 2 },
          { minWidth: 960, cards: 3 },
          { minWidth: 1280, cards: 4 }
        ]}
        items={system.environments}
        variant='full-page'
      />
    </RadSpaceBetween>
  )
}

function Infrastructures({ system }) {
  return (
    <RadSpaceBetween size='s'>
      <RadHeader
        counter={'(' + system.infrastructures.length + ')'}
        variant='h2'
      >
        Infrastructures
        {system.infrastructures.length === 0 &&
          <RadBox color='text-status-inactive' padding={{ top: 'xxs' }}>No infrastructures added to this system.</RadBox>}
      </RadHeader>
      <RadCards
        cardDefinition={{
          header: item => item.name,
          sections: [
            {
              id: 'type',
              header: 'Type',
              content: item => item.type.name
            },
            {
              id: 'url',
              header: 'URL',
              content: item => item.url ? <RadLink href={item.url}>{item.url}</RadLink> : '-'
            },
            {
              id: 'status',
              header: 'Status',
              content: item => item.status.name
            },
            {
              id: 'decommissionDate',
              header: 'Decommission Date',
              content: item => item.decommissionDate != null ? formatDate(item.decommissionDate) : '-'
            },
            {
              id: 'notes',
              header: 'Notes',
              content: item => item.notes ?? '-'
            }
          ]
        }}
        cardsPerRow={[
          { cards: 1 },
          { minWidth: 640, cards: 2 },
          { minWidth: 960, cards: 3 },
          { minWidth: 1280, cards: 4 }
        ]}
        items={system.infrastructures}
        variant='full-page'
      />
    </RadSpaceBetween>
  )
}
