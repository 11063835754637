import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { RadCheckbox } from '../common/RadCheckbox'

export function SystemEdit() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { systemId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: system } = useGet(systemId ? `/api/system/${systemId}` : null)
  const { data: systemStatusOptions } = useGet('/api/option/type?entity=systemStatus&required')
  const { data: systemAccountTypeOptions } = useGet('/api/option/type?entity=systemAccountType')
  const { data: yesNoOptions } = useGet('/api/option/boolean-options')

  useEffect(() => {
    const defaultFormValues = { clientId: searchParams.get('clientId'), environments: [], infrastructures: [] }
    setFormValues(system ?? defaultFormValues)
  }, [system])

  const create = usePost('/api/system', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/system/${resp.id}`) })
  const update = usePut(`/api/system/${systemId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/system/${systemId}`) })

  async function saveChanges() {
    if (systemId) { update() } else { create() }
  }

  async function cancel() {
    if (systemId) {
      navigate(searchParams.get('redirectURL') ?? `/system/${systemId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/system')
    }
  }

  if (
    formValues != null &&
    systemStatusOptions != null &&
    systemAccountTypeOptions != null &&
    yesNoOptions != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id ? 'Edit System' : 'New System'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <Details formValues={formValues} setFormValues={setFormValues} systemStatusOptions={systemStatusOptions} systemAccountTypeOptions={systemAccountTypeOptions} yesNoOptions={yesNoOptions} />
                <Environments formValues={formValues} setFormValues={setFormValues} systemStatusOptions={systemStatusOptions} />
                <Infrastructures formValues={formValues} setFormValues={setFormValues} systemStatusOptions={systemStatusOptions} />
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function Details({ formValues, setFormValues, systemStatusOptions, systemAccountTypeOptions, yesNoOptions }) {
  const { data: organizations } = useGet('/api/option/organization?required')
  const { data: systemTypeOptions } = useGet('/api/option/type?entity=systemType&required')

  if (
    organizations != null &&
    systemTypeOptions != null
  ) {
    return (
      <RadContainer
        header={
          <RadHeader variant='h2'>
            Details
          </RadHeader>
        }
      >
        <RadSpaceBetween size='l'>
          <RadGrid
            gridDefinition={[
              { colspan: { default: 12, xs: 6 } },
              { colspan: { default: 12, xs: 6 } }
            ]}
          >
            <RadFormField label='Name' field='name' required>
              <RadInput
                placeholder='Enter name'
                ariaRequired
                value={formValues.name ?? ''}
                onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
              />
            </RadFormField>
            <RadFormField label='Client' field='clientId' required>
              <RadSelect
                filterType='auto'
                selectedOption={formValues.clientId ? organizations.find(x => x.value === formValues.clientId.toString()) : { value: '', label: '-' }}
                onChange={({ detail }) => {
                  if (detail.selectedOption.value !== '') {
                    setFormValues({ ...formValues, clientId: parseInt(detail.selectedOption.value) })
                  } else {
                    setFormValues({ ...formValues, clientId: null })
                  }
                }}
                options={organizations}
                selectedAriaLabel='Selected'
                empty='No matches found'
              />
            </RadFormField>
          </RadGrid>
          <RadFormField label='Description' field='description' stretch>
            <RadTextarea
              rows={4}
              placeholder='Enter description'
              value={formValues.description ?? ''}
              onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
            />
          </RadFormField>
          <RadGrid
            gridDefinition={[
              { colspan: { default: 12, xs: 3 } },
              { colspan: { default: 12, xs: 3 } },
              { colspan: { default: 12, xs: 3 } },
              { colspan: { default: 12, xs: 3 } }
            ]}
          >
            <RadFormField label='Type' field='typeId' required>
              <RadSelect
                filterType='auto'
                selectedOption={formValues.typeId ? systemTypeOptions.find(x => x.value === formValues.typeId.toString()) : { value: '', label: '-' }}
                onChange={({ detail }) => {
                  if (detail.selectedOption.value !== '') {
                    setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                  } else {
                    setFormValues({ ...formValues, typeId: null })
                  }
                }}
                options={systemTypeOptions}
                selectedAriaLabel='Selected'
                empty='No matches found'
              />
            </RadFormField>
            <RadFormField label='Status' field='statusId' required>
              <RadSelect
                filterType='auto'
                selectedOption={formValues.statusId ? systemStatusOptions.find(x => x.value === formValues.statusId.toString()) : { value: '', label: '-' }}
                onChange={({ detail }) => {
                  if (detail.selectedOption.value !== '') {
                    setFormValues({ ...formValues, statusId: parseInt(detail.selectedOption.value) })
                  } else {
                    setFormValues({ ...formValues, statusId: null })
                  }
                }}
                options={systemStatusOptions}
                selectedAriaLabel='Selected'
                empty='No matches found'
              />
            </RadFormField>
            <RadFormField label='Date First Launched' field='dateFirstLaunched' required>
              <RadInput
                placeholder='Enter date first launched'
                ariaRequired
                type='date'
                value={formValues.dateFirstLaunched ?? ''}
                onChange={({ detail }) => setFormValues({ ...formValues, dateFirstLaunched: detail.value })}
              />
            </RadFormField>
            <RadFormField label='Decommission Date' field='decommissionDate'>
              <RadInput
                placeholder='Enter decommission date'
                ariaRequired
                type='date'
                value={formValues.decommissionDate ?? ''}
                onChange={({ detail }) => setFormValues({ ...formValues, decommissionDate: detail.value })}
              />
            </RadFormField>
            <RadFormField label='Account Type' field='systemAccountTypeId'>
              <RadSelect
                filterType='auto'
                selectedOption={formValues.systemAccountTypeId ? systemAccountTypeOptions.find(x => x.value === formValues.systemAccountTypeId.toString()) : { value: '', label: '-' }}
                onChange={({ detail }) => {
                  if (detail.selectedOption.value !== '') {
                    setFormValues({ ...formValues, systemAccountTypeId: parseInt(detail.selectedOption.value) })
                  } else {
                    setFormValues({ ...formValues, systemAccountTypeId: null })
                  }
                }}
                options={systemAccountTypeOptions}
                selectedAriaLabel='Selected'
                empty='No matches found'
              />
            </RadFormField>
            <RadFormField label='Is Contract Current?' field='isContractCurrent'>
              <RadCheckbox
                key={yesNoOptions.label}
                onChange={({ detail }) => {
                  const isContractCurrent = detail.checked;
                  setFormValues({ ...formValues, isContractCurrent });
                }}
                checked={formValues.isContractCurrent}
              >
                {yesNoOptions.label}
              </RadCheckbox>
            </RadFormField>
            <RadFormField label='Is Reimbursed?' field='isReimbursed'>
              <RadCheckbox
                key={yesNoOptions.label}
                onChange={({ detail }) => {
                  const isReimbursed = detail.checked;
                  setFormValues({ ...formValues, isReimbursed });
                }}
                checked={formValues.isReimbursed}
              >
                {yesNoOptions.label}
              </RadCheckbox>
            </RadFormField>
            <RadFormField label='Is Marked Up?' field='isMarkedUp'>
              <RadCheckbox
                key={yesNoOptions.label}
                onChange={({ detail }) => {
                  const isMarkedUp = detail.checked;
                  setFormValues({ ...formValues, isMarkedUp });
                }}
                checked={formValues.isMarkedUp}
              >
                {yesNoOptions.label}
              </RadCheckbox>
            </RadFormField>
            <RadFormField label='Is Licensed?' field='isLicensed'>
              <RadCheckbox
                key={yesNoOptions.label}
                onChange={({ detail }) => {
                  const isLicensed = detail.checked;
                  setFormValues({ ...formValues, isLicensed });
                }}
                checked={formValues.isLicensed}
              >
                {yesNoOptions.label}
              </RadCheckbox>
            </RadFormField>
          </RadGrid>
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function Environments({ formValues, setFormValues, systemStatusOptions }) {
  const environments = formValues.environments

  return (
    <RadContainer
      header={
        <RadHeader
          counter={'(' + environments.length + ')'}
          variant='h2'
        >
          Environments
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        {environments.length > 0 &&
          <RadSpaceBetween size='xxs'>
            {environments.map((item) =>
              <EnvironmentEditor
                key={`environment-${item.id ?? item.uuid}`}
                item={item}
                formValues={formValues}
                setFormValues={setFormValues}
                systemStatusOptions={systemStatusOptions}
              />
            )}
          </RadSpaceBetween>}
        {environments.length === 0 &&
          <RadBox color='text-status-inactive'>No environments added to this system.</RadBox>}
        <RadButton
          onClick={() => {
            environments.push({ uuid: uuidv4(), environment: {} })
            setFormValues({ ...formValues, environments })
          }}
        >
          Add environment
        </RadButton>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function EnvironmentEditor({ item, formValues, setFormValues, systemStatusOptions }) {
  const environments = formValues.environments

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 2 } },
          { colspan: { default: 12, xs: 2 } }
        ]}
      >
        <RadFormField label='Name' field={`environment.${item.id ?? item.uuid}.name`} required>
          <RadInput
            ariaRequired
            value={item.name}
            onChange={({ detail }) => {
              item.name = detail.value
              setFormValues({ ...formValues, environments })
            }}
          />
        </RadFormField>
        <RadFormField label='URL' field={`environment.${item.id ?? item.uuid}.url`}>
          <RadInput
            value={item.url}
            onChange={({ detail }) => {
              item.url = detail.value
              setFormValues({ ...formValues, environments })
            }}
          />
        </RadFormField>
        <RadFormField label='Status' field={`environment.${item.id ?? item.uuid}.statusId`} required>
          <RadSelect
            filteringType='auto'
            selectedOption={systemStatusOptions.find(x => parseInt(x.value) === item.statusId)}
            onChange={({ detail }) => {
              item.statusId = parseInt(detail.selectedOption.value)
              setFormValues({ ...formValues, environments })
            }}
            options={systemStatusOptions}
            enteredTextLabel={value => value}
            selectedAriaLabel='Selected'
            placeholder='Choose a status'
            empty='No matches found'
          />
        </RadFormField>
        <RadBox padding={{ top: 'xl' }}>
          <RadButton
            wrapText={false}
            onClick={() => {
              const index = environments.indexOf(item)
              environments.splice(index, 1)
              setFormValues({ ...formValues, environments })
            }}
          >
            Remove environment
          </RadButton>
        </RadBox>
      </RadGrid>
      <RadFormField label='Description' field={`environment.${item.id ?? item.uuid}.description`} stretch>
        <RadTextarea
          value={item.description}
          onChange={({ detail }) => {
            item.description = detail.value
            setFormValues({ ...formValues, environments })
          }}
        />
      </RadFormField>
      {item !== environments[environments.length - 1] && <RadDivider />}
    </RadSpaceBetween>
  )
}

function Infrastructures({ formValues, setFormValues, systemStatusOptions }) {
  const infrastructures = formValues.infrastructures

  return (
    <RadContainer
      header={
        <RadHeader
          counter={'(' + infrastructures.length + ')'}
          variant='h2'
        >
          Infrastructures
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        {infrastructures.length > 0 &&
          <RadSpaceBetween size='xxs'>
            {infrastructures.map((item) =>
              <InfrastructureEditor
                key={`environment-${item.id ?? item.uuid}`}
                item={item}
                formValues={formValues}
                setFormValues={setFormValues}
                systemStatusOptions={systemStatusOptions}
              />
            )}
          </RadSpaceBetween>}
        {infrastructures.length === 0 &&
          <RadBox color='text-status-inactive'>No infrastructures added to this system.</RadBox>}
        <RadButton
          onClick={() => {
            infrastructures.push({ uuid: uuidv4(), environment: {} })
            setFormValues({ ...formValues, infrastructures })
          }}
        >
          Add infrastructure
        </RadButton>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function InfrastructureEditor({ item, formValues, setFormValues, systemStatusOptions }) {
  const infrastructures = formValues.infrastructures
  const { data: systemInfrastructureTypeOptions } = useGet('/api/option/type?entity=systemInfrastructureType&required')

  if (
    systemInfrastructureTypeOptions != null
  ) {
    return (
      <RadSpaceBetween size='l'>
        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, xs: 5 } },
            { colspan: { default: 12, xs: 5 } },
            { colspan: { default: 12, xs: 2 } }
          ]}
        >
          <RadFormField label='Name' field={`environment.${item.id ?? item.uuid}.name`} required>
            <RadInput
              ariaRequired
              value={item.name}
              onChange={({ detail }) => {
                item.name = detail.value
                setFormValues({ ...formValues, infrastructures })
              }}
            />
          </RadFormField>

          <RadFormField label='URL' field={`environment.${item.id ?? item.uuid}.url`}>
            <RadInput
              value={item.url}
              onChange={({ detail }) => {
                item.url = detail.value
                setFormValues({ ...formValues, infrastructures })
              }}
            />
          </RadFormField>

          <RadBox padding={{ top: 'xl' }}>
            <RadButton
              wrapText={false}
              onClick={() => {
                const index = infrastructures.indexOf(item)
                infrastructures.splice(index, 1)
                setFormValues({ ...formValues, infrastructures })
              }}
            >
              Remove infrastructure
            </RadButton>

          </RadBox>
        </RadGrid>
        <RadGrid
          gridDefinition={[
            { colspan: { default: 12, xs: 3 } },
            { colspan: { default: 12, xs: 3 } }
          ]}
        >
          <RadFormField label='Type' field={`environment.${item.id ?? item.uuid}.typeId`} required>
            <RadSelect
              filteringType='auto'
              selectedOption={systemInfrastructureTypeOptions.find(x => parseInt(x.value) === item.typeId)}
              onChange={({ detail }) => {
                item.typeId = parseInt(detail.selectedOption.value)
                setFormValues({ ...formValues, infrastructures })
              }}
              options={systemInfrastructureTypeOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a type'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='Status' field={`environment.${item.id ?? item.uuid}.statusId`} required>
            <RadSelect
              filteringType='auto'
              selectedOption={systemStatusOptions.find(x => parseInt(x.value) === item.statusId)}
              onChange={({ detail }) => {
                item.statusId = parseInt(detail.selectedOption.value)
                setFormValues({ ...formValues, infrastructures })
              }}
              options={systemStatusOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a status'
              empty='No matches found'
            />
          </RadFormField>
        </RadGrid>
        <RadFormField label='Notes' field={`environment.${item.id ?? item.uuid}.notes`} stretch>
          <RadTextarea
            value={item.notes}
            onChange={({ detail }) => {
              item.notes = detail.value
              setFormValues({ ...formValues, infrastructures })
            }}
          />
        </RadFormField>
        {item !== infrastructures[infrastructures.length - 1] && <RadDivider />}
      </RadSpaceBetween>
    )
  }
}
