import React, { } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadTable } from '../common/RadTable'
import { formatDateForInputs, formatCurrency } from '../common/utilities'

export function OrganizationDetail() {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { data: organization } = useGet(`/api/organization/${organizationId}`)
  const remove = useDelete(`/api/organization/${organizationId}`, () => { navigate('/organization') })
  const confirmDelete = useConfirm('Delete organization?', 'Delete organization permanently? This action cannot be undone.', 'Delete', () => { remove() })

  function edit(evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (
    organization != null
  ) {
    // const projects = []
    // for (const contract of organization.clientContracts) {
    //   for (const service of contract.services) {
    //     if (service.project != null && projects.find(p => p.id === service.project.id) == null) {
    //       projects.push(service.project)
    //     }
    //   }
    // }
    // for (const contract of organization.funderContracts) {
    //   for (const service of contract.services) {
    //     if (service.project != null && projects.find(p => p.id === service.project.id) == null) {
    //       projects.push(service.project)
    //     }
    //   }
    // }

    return (
      <RadAppLayout
        name={organization.fullName}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={organization.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={organization.description}
          >
            {organization.fullName}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <Details organization={organization} />
            <Contacts organization={organization} />
            <Projects organization={organization} />
            <Contracts organization={organization} />
            <Invoices organization={organization} />
            <Systems organization={organization} />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function Details({ organization }) {
  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
      }
    >
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Parent Organization
          </RadBox>
          {organization.parent && <RadLink href={`/organization/${organization.parent.id}`}>{organization.parent.commonName}</RadLink>}
          {!organization.parent && <div>-</div>}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Common Name
          </RadBox>
          <div>{organization.commonName ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Full Name
          </RadBox>
          <div>{organization.fullName ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Acronym
          </RadBox>
          <div>{organization.acronym ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Type
          </RadBox>
          <div>{organization.organizationType?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Annual Revenue
          </RadBox>
          <div>{organization.annualRevenue !== null ? formatCurrency(organization.annualRevenue) : '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Location
          </RadBox>
          <div>{organization.location ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Role
          </RadBox>
          <div>{organization.organizationRole?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Team Size
          </RadBox>
          <div>{organization.organizationTeamSize?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Domain
          </RadBox>
          <div>{organization.organizationDomain?.name ?? '-'}</div>
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}

function Contacts({ organization }) {
  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + organization.contacts.length + ')'}
        >
          Contacts
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadTable
          trackBy='id'
          variant='embedded'
          items={organization.contacts}
          columnDefinitions={[
            {
              id: 'id',
              header: 'Name',
              cell: e => <RadLink href={`/person/${e.person.id}`}>{e.person.fullName}</RadLink>
            },
            {
              id: 'title',
              header: 'Title',
              cell: e => e.title ?? '-'
            },
            {
              id: 'primaryEmployer',
              header: 'Primary Employer',
              cell: e => e.primaryEmployer ? 'Yes' : 'No'
            },
            {
              id: 'actions',
              header: 'Actions',
              cell: e =>
                <RadButton
                  href={`/person/${e.person.id}/edit?redirectURL=/organization/${organization.id}`}
                >
                  Edit
                </RadButton>
            }
          ]}
          empty={
            <RadBox textAlign='center' padding={{ top: 'l' }}>
              <RadBox>
                No contacts added to this organization.
              </RadBox>
            </RadBox>
          }
        />
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Projects({ organization }) {
  const { data: projects } = useGet(`/api/project?clientId=${organization.id}`)

  if (projects != null) {
    return (
      <RadContainer
        header={
          <RadHeader
            variant='h2'
            counter={'(' + (projects ? projects.length : 0) + ')'}
          >
            Projects
          </RadHeader>
        }
      >
        <RadSpaceBetween size='l'>
          <RadTable
            trackBy='id'
            variant='embedded'
            items={projects}
            columnDefinitions={[
              {
                id: 'id',
                header: 'Number',
                cell: e => <RadLink href={`/project/${e.id}`}>{e.number}</RadLink>
              },
              {
                id: 'name',
                header: 'name',
                cell: e => e.name ?? '-'
              },
              {
                id: 'status',
                header: 'Status',
                cell: e => e.projectStatus?.name ?? '-'
              },
              {
                id: 'type',
                header: 'Type',
                cell: e => e.projectType?.name ?? '-'
              },
              {
                id: 'actions',
                header: 'Actions',
                cell: e =>
                  <RadButton
                    href={`/project/${e.id}/edit?redirectURL=/organization/${organization.id}`}
                  >
                    Edit
                  </RadButton>
              }
            ]}
            empty={
              <RadBox textAlign='center' padding={{ top: 'l' }}>
                <RadBox>
                  No projects added to this organization.
                </RadBox>
              </RadBox>
            }
          />
        </RadSpaceBetween>
      </RadContainer>
    )
  }
}

function Contracts({ organization }) {
  const navigate = useNavigate()
  const allContracts = organization.clientContracts
    .concat(organization.funderContracts)
    .filter((x, i, a) => a.findIndex(t => t.id === x.id) === i)
    .sort((a, b) => a.effectiveDate - b.effectiveDate)

  function getRole(contract) {
    const roles = []
    if (contract.clientId === organization.id) { roles.push('Client') }
    if (contract.funderId === organization.id) { roles.push('Funder') }
    return roles.join(' and ')
  }

  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + allContracts.length + ')'}
        >
          Contracts
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadTable
          trackBy='id'
          variant='embedded'
          items={allContracts}
          columnDefinitions={[
            {
              id: 'id',
              header: '#',
              cell: e => <RadLink href={`/contract/${e.id}`}>{e.id}</RadLink>
            },
            {
              id: 'name',
              header: 'Name',
              cell: e => e.name
            },
            {
              id: 'role',
              header: 'Role',
              cell: e => getRole(e)
            },
            {
              id: 'effectiveDate',
              header: 'Effective Date',
              cell: e => formatDateForInputs(e.effectiveDate)
            },
            {
              id: 'expirationDate',
              header: 'Expiration Date',
              cell: e => e.expirationDate != null ? formatDateForInputs(e.expirationDate) : '-'
            },
            {
              id: 'totalValue',
              header: 'Amount',
              cell: e => formatCurrency(e.totalValue)
            },
            {
              id: 'contractStatus',
              header: 'Status',
              cell: e => e.contractStatus?.name
            },
            {
              id: 'actions',
              header: 'Actions',
              cell: e =>
                <RadButton
                  href={`/contract/${e.id}/edit?redirectURL=/organization/${organization.id}`}
                >
                  Edit
                </RadButton>
            }
          ]}
          empty={
            <RadBox textAlign='center' padding={{ top: 'l' }}>
              <RadBox>
                No contracts added to this organization.
              </RadBox>
            </RadBox>
          }
        />
        <RadButton
          onClick={() => {
            navigate(`/contract/create?clientId=${organization.id}&funderId=${organization.id}&redirectURL=/organization/${organization.id}`)
          }}
        >
          Add new contract
        </RadButton>
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Invoices({ organization }) {
  const allInvoices = organization.clientContracts.map(contract => contract.invoices)
    .concat(organization.funderContracts.map(contract => contract.invoices))
    .flat()
    .reduce((unique, invoice) => {
      return unique.findIndex(item => item.id === invoice.id) !== -1 ? unique : [...unique, invoice]
    }, [])

  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + allInvoices.length + ')'}
        >
          Invoices
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadTable
          trackBy='id'
          variant='embedded'
          items={allInvoices}
          columnDefinitions={[
            // {
            //   id: 'invoiceId',
            //   header: '#',
            //   cell: e => { e.id }
            // },
            {
              id: 'title',
              header: 'Title',
              cell: e => e.title
            },
            {
              id: 'dateRequested',
              header: 'Date Requested',
              cell: e => formatDateForInputs(e.dateRequested)
            },
            {
              id: 'dateToBeSent',
              header: 'Date To Be Sent',
              cell: e => e.dateToBeSent != null ? formatDateForInputs(e.dateToBeSent) : '-'
            },
            {
              id: 'status',
              header: 'Status',
              cell: e => e.invoiceStatus?.name ?? '-'
            },
            {
              id: 'contractId',
              header: 'Contract #',
              cell: e => <RadLink href={`/contract/${e.contractId}`}>{e.contractId}</RadLink>
            }
          ]}
          empty={
            <RadBox textAlign='center' padding={{ top: 'l' }}>
              <RadBox>
                No invoices added to this organization.
              </RadBox>
            </RadBox>
          }
        />
      </RadSpaceBetween>
    </RadContainer>
  )
}

function Systems({ organization }) {
  const navigate = useNavigate()

  return (
    <RadContainer
      header={
        <RadHeader
          variant='h2'
          counter={'(' + organization.systems.length + ')'}
        >
          Systems
        </RadHeader>
      }
    >
      <RadSpaceBetween size='l'>
        <RadTable
          trackBy='id'
          variant='embedded'
          items={organization.systems}
          columnDefinitions={[
            {
              id: 'id',
              header: '#',
              cell: e => <RadLink href={`/system/${e.id}`}>{e.id}</RadLink>
            },
            {
              id: 'name',
              header: 'Name',
              cell: e => e.name
            },
            {
              id: 'type',
              header: 'Type',
              cell: e => e.type.name
            },
            {
              id: 'status',
              header: 'Status',
              cell: e => e.status.name
            },
            {
              id: 'dateFirstLaunched',
              header: 'Date First Launched',
              cell: e => formatDateForInputs(e.dateFirstLaunched)
            },
            {
              id: 'decommissionDate',
              header: 'Decommission Date',
              cell: e => e.decommissionDate != null ? formatDateForInputs(e.decommissionDate) : '-'
            },
            {
              id: 'actions',
              header: 'Actions',
              cell: e =>
                <RadButton
                  href={`/system/${e.id}/edit?redirectURL=/organization/${organization.id}`}
                >
                  Edit
                </RadButton>
            }
          ]}
          empty={
            <RadBox textAlign='center' padding={{ top: 'l' }}>
              <RadBox>
                No systems added to this organization.
              </RadBox>
            </RadBox>
          }
        />
        <RadButton
          onClick={() => {
            navigate(`/system/create?clientId=${organization.id}&redirectURL=${window.location.pathname}`)
          }}
        >
          Add new system
        </RadButton>
      </RadSpaceBetween>
    </RadContainer>
  )
}
