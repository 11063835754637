import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from './../hooks/usePost'
import { usePut } from './../hooks/usePut'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function PersonEdit () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { personId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: person } = useGet(personId ? `/api/person/${personId}` : null)
  const { data: organizationOptions } = useGet('/api/option/organization?required')
  const create = usePost('/api/person', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/person/${resp.id}`) })
  const update = usePut(`/api/person/${personId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/person/${personId}`) })

  useEffect(() => {
    const defaultFormValues = { isPrivate: false, organizations: [], shares: [], tags: [] }
    setFormValues(person ?? defaultFormValues)
  }, [person])

  async function cancel () {
    if (personId != null) {
      navigate(searchParams.get('redirectURL') ?? `/person/${personId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/person')
    }
  }

  async function saveChanges () {
    if (personId != null) {
      update()
    } else {
      create()
    }
  }

  if (
    formValues != null &&
    organizationOptions != null
  ) {
    return (
      <RadAppLayout
        name={formValues.fullName}
        contentHeader={
          <RadHeader variant='h1'>{personId ? 'Edit' : 'New'} Person</RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='First Name *' field='firstName'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter first name'
                        value={formValues.firstName}
                        onChange={({ detail }) => setFormValues({ ...formValues, firstName: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Middle Name' field='middleName' optional>
                      <RadInput
                        ariaRequired
                        placeholder='Enter middle name'
                        value={formValues.middleName}
                        onChange={({ detail }) => setFormValues({ ...formValues, middleName: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Last Name *' field='lastName'>
                      <RadInput
                        ariaRequired
                        placeholder='Enter last name'
                        value={formValues.lastName}
                        onChange={({ detail }) => setFormValues({ ...formValues, lastName: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Preferred Name' field='preferredName' optional>
                      <RadInput
                        placeholder='Enter preferred name'
                        value={formValues.preferredName}
                        onChange={({ detail }) => setFormValues({ ...formValues, preferredName: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Email' field='email' optional>
                      <RadInput
                        type='email'
                        inputMode='email'
                        placeholder='Enter email'
                        value={formValues.email}
                        onChange={({ detail }) => setFormValues({ ...formValues, email: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Phone' field='phone' optional>
                      <RadInput
                        type='tel'
                        inputMode='tel'
                        placeholder='Enter phone'
                        value={formValues.phone}
                        onChange={({ detail }) => setFormValues({ ...formValues, phone: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Float ID' field='floatId' optional>
                      <RadInput
                        placeholder='Enter float ID'
                        value={formValues.floatId}
                        onChange={({ detail }) => setFormValues({ ...formValues, floatId: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>

                <RadContainer
                  header={
                    <RadHeader variant='h2'>Organizations</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const organizations = formValues.organizations
                      organizations.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, organizations })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const organizations = [...formValues.organizations]
                      organizations.splice(itemIndex, 1)
                      setFormValues({ ...formValues, organizations })
                    }}
                    items={formValues.organizations}
                    addButtonText='Add new organization'
                    definition={[
                      {
                        label: 'Organization',
                        control: item => {
                          const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.organizationId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={organizationOptions.find(x => x.value === item.organizationId?.toString())}
                                onChange={({ detail }) => {
                                  const organizations = formValues.organizations
                                  organization.organizationId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, organizations })
                                }}
                                options={organizationOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a organization'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Title',
                        control: item => {
                          const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.title`}>
                              <RadInput
                                placeholder='Enter title'
                                value={item.title}
                                onChange={({ detail }) => {
                                  const organizations = formValues.organizations
                                  organization.title = detail.value
                                  setFormValues({ ...formValues, organizations })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Primary Employer',
                        control: item => {
                          const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.primaryEmployer`}>
                              <RadCheckbox
                                checked={organization.primaryEmployer}
                                onChange={({ detail }) => {
                                  const organizations = formValues.organizations
                                  organization.primaryEmployer = detail.checked
                                  setFormValues({ ...formValues, organizations })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No organizations added to this person.'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
