import React, { useContext } from 'react'
import { AppContext } from './../App'
import { useLocation } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import SideNavigation from '@cloudscape-design/components/side-navigation'
import { RadExpandableSection } from './RadExpandableSection'

// https://cloudscape.design/components/side-navigation
export function RadSideNavigation ({ setNavigationOpen }) {
  const { setError } = useContext(AppContext)
  const location = useLocation()
  const isMobile = useIsMobile()
  const rootHref = () => {
    let path = location.pathname
    let last = path.split('/').pop()
    while (!isNaN(parseInt(last, 10)) || last === 'edit') {
      path = path.replace(`/${last}`, '')
      last = path.split('/').pop()
    }
    return path
  }
  const [activeHref, setActiveHref] = React.useState(rootHref)

  return (

    <div>
      <SideNavigation
        activeHref={activeHref}
        header={{ href: '/', logo: { alt: 'logo', src: '/logo.png' } }}
        onFollow={(event) => {
          if (isMobile) setNavigationOpen(false)
          setError(null) // Chipi - I might not need this
          setActiveHref(event.detail.href)
        }}
        items={[
          { type: 'link', text: 'Dashboard', href: '/' },
          { type: 'link', text: 'Organizations', href: '/organization' },
          { type: 'link', text: 'Contracts', href: '/contract' },
          { type: 'link', text: 'People', href: '/person' },
          { type: 'link', text: 'Projects', href: '/project' },
          { type: 'link', text: 'Systems', href: '/system' },
          // { type: 'link', text: 'MIO', href: '/mio' }
        ]}
      />

      <RadExpandableSection headerText='Admin'>
        <SideNavigation
          activeHref={activeHref}
          onFollow={(event) => {
            if (isMobile) setNavigationOpen(false)
            setError(null) // Chipi - I might not need this
            setActiveHref(event.detail.href)
          }}
          items={[
            { type: 'link', text: 'Service Lines', href: '/service-line' },
            { type: 'link', text: 'Types', href: '/type' },
            { type: 'link', text: 'Users', href: '/user' },
            { type: 'link', text: 'Roles', href: '/role' }
          ]}
        />
      </RadExpandableSection>

      <RadExpandableSection headerText='Reporting'>
        <SideNavigation
          activeHref={activeHref}
          onFollow={(event) => {
            if (isMobile) setNavigationOpen(false)
            setError(null) // Chipi - I might not need this
            setActiveHref(event.detail.href)
          }}
          items={[
            { type: 'link', text: 'Hosting', href: '/hosting' },
            { type: 'link', text: 'Invoices', href: '/invoices' },
            { type: 'link', text: 'Licenses', href: '/licenses' },
            { type: 'link', text: 'Service Milestones', href: '/contract-service-milestones' },
            { type: 'link', text: 'Reporting Links', href: '/reporting-links' }
          ]}
        />
      </RadExpandableSection>
    </div>
  )
}
