import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadMultiselectFilter } from '../common/RadMultiselectFilter'
import { formatDate } from '../common/utilities'

export function SystemList () {
  const { data: systemStatusOptions } = useGet('/api/option/type?entity=systemStatus&required')
  const { data: systemTypeOptions } = useGet('/api/option/type?entity=systemType&required')
  const { data: yesNoOptions } = useGet('/api/option/boolean-options')

  if (
    systemStatusOptions != null &&
    systemTypeOptions != null
  ) {
    return (
      <RadAdaptiveList
        entity='system'
        filters={
          <>
            <RadMultiselectFilter styles={{ flexGrow: 0 }} name='status' param='statusIds' options={systemStatusOptions} />
            <RadMultiselectFilter styles={{ flexGrow: 0 }} name='type' param='typeIds' options={systemTypeOptions} /> 
            <RadMultiselectFilter styles={{ flexGrow: 0 }} name='contract status' param='isContractCurrent' options={yesNoOptions} />
            <RadMultiselectFilter styles={{ flexGrow: 0 }} name='reimbursed' param='isReimbursed' options={yesNoOptions} />
            <RadMultiselectFilter styles={{ flexGrow: 0 }} name='licensed' param='isLicensed' options={yesNoOptions} />
          </>
        }
        fields={[
          { header: 'ID', name: 'id', link: true },
          'name',
          'client.commonName',
          'type.name',
          'status.name',
          { header: 'Date First Launched', content: (item) => formatDate(item.dateFirstLaunched) },
          { header: 'Decommission Date', content: (item) => formatDate(item.decommissionDate) ?? '-' }
        ]}
      />
    )
  }
}
