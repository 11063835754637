import React, { } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadTable } from '../common/RadTable'
import { formatCurrency, formatDate } from '../common/utilities'

export function ProjectDetail() {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { data: project } = useGet(`/api/project/${projectId}`)
  const remove = useDelete(`/api/project/${projectId}`, () => { navigate('/project') })
  const confirmDelete = useConfirm('Delete project?', 'Delete project permanently? This action cannot be undone.', 'Delete', () => { remove() })

  function edit(evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (
    project != null
  ) {
    return (
      <RadAppLayout
        name={project.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={project.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={project.description}
          >
            {project.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Number
                  </RadBox>
                  <div>{project.number || project.id}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Name
                  </RadBox>
                  <div>{project.name}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Client
                  </RadBox>
                  <div>
                  <RadLink href={`/organization/${project.clientId}`}>{project.client?.commonName ?? '-'}</RadLink>
                    </div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Project Type
                  </RadBox>
                  <div>{project.projectType?.name ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Project Status
                  </RadBox>
                  <div>{project.projectStatus?.name ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Float ID
                  </RadBox>
                  <div>{project.floatId ?? '-'}</div>
                </div>
              </RadColumnLayout>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Post Project Data
                </RadHeader>
              }
            >
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Goal
                  </RadBox>
                  <div>{project.goal ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Solution
                  </RadBox>
                  <div>{project.solution ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Tooling
                  </RadBox>
                  <div>{project.tooling ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Integrations
                  </RadBox>
                  <div>{project.integrations ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Data Migration
                  </RadBox>
                  <div>{project.dataMigration === true ? 'Yes' : 'No'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Planned Start Date
                  </RadBox>
                  <div>{project.plannedStartDate != null ? formatDate(project.plannedStartDate) : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Actual Start Date
                  </RadBox>
                  <div>{project.actualStartDate != null ? formatDate(project.actualStartDate) : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Initial Budget
                  </RadBox>
                  <div>{project.initialBudget != null ? formatCurrency(project.initialBudget) : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Final Budget
                  </RadBox>
                  <div>{project.finalBudget != null ? formatCurrency(project.finalBudget) : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Logged Hours
                  </RadBox>
                  <div>{project.loggedHours ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Billable Hours
                  </RadBox>
                  <div>{project.billableHours ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Non Billable Hours
                  </RadBox>
                  <div>{project.nonBillableHours ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Project Rate
                  </RadBox>
                  <div>{project.projectRate != null ? formatCurrency(project.projectRate) : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Key Lessons Learned
                  </RadBox>
                  <div>{project.keyLessonsLearned ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Compliance
                  </RadBox>
                  <div>{
                    project.compliance.length === 0
                      ? '-'
                      : project.compliance.map(x => x.name).join(', ')
                  }
                  </div>
                </div>
              </RadColumnLayout>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Team
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadTable
                  trackBy='id'
                  variant='embedded'
                  items={project.team}
                  columnDefinitions={[
                    {
                      id: 'id',
                      header: 'Name',
                      cell: e => <RadLink href={`/person/${e.person.id}`}>{e.person.fullName}</RadLink>
                    },
                    {
                      id: 'role',
                      header: 'Role',
                      cell: e => e.role
                    },
                    {
                      id: 'actions',
                      header: 'Actions',
                      cell: e =>
                        <RadButton
                          href={`/person/${e.person.id}/edit?redirectURL=/project/${projectId}`}
                        >
                          Edit
                        </RadButton>
                    }
                  ]}
                  empty={
                    <RadBox textAlign='center' padding={{ top: 'l' }}>
                      <RadBox>
                        No team members added to this project.
                      </RadBox>
                    </RadBox>
                  }
                />
              </RadSpaceBetween>
            </RadContainer>
            <RadCards
              cardDefinition={{
                header: item => item.service.serviceLine.name,
                sections: [
                  {
                    id: 'id',
                    header: 'ID',
                    content: item =>
                    <RadLink href={`/contract/${item.ContractId}`}>{item.number || item.id}</RadLink>
                  },
                  {
                    id: 'service',
                    header: 'Service',
                    content: item => item.service.name
                  },
                  {
                    id: 'values',
                    content: item => {
                      return (
                        <RadSpaceBetween size='m'>
                          {item.projectId != null &&
                            <RadBox variant='awsui-key-label'>
                              Project
                              <RadBox>{item.project.name}</RadBox>
                            </RadBox>}
                          {item.chartOfAccountId != null &&
                            <RadBox variant='awsui-key-label'>
                              Chart of Account
                              <RadBox>{item.chartOfAccount.name}</RadBox>
                            </RadBox>}
                          {item.revenueDesignationId != null &&
                            <RadBox variant='awsui-key-label'>
                              Revenue Designation
                              <RadBox>{item.revenueDesignation.name}</RadBox>
                            </RadBox>}
                          {item.totalValue != null &&
                            <RadBox variant='awsui-key-label'>
                              Total Value
                              <RadBox>${item.totalValue}</RadBox>
                            </RadBox>}
                          {item.invoiceApproverId != null &&
                            <RadBox variant='awsui-key-label'>
                              Invoice Approver
                              <RadBox>
                                <RadLink href={`/person/${item.invoiceApproverId}`}>{item.invoiceApprover.fullName}</RadLink>
                              </RadBox>
                            </RadBox>}
                          {item.startDate != null &&
                            <RadBox variant='awsui-key-label'>
                              Start Date
                              <RadBox>{formatDate(item.startDate)}</RadBox>
                            </RadBox>}
                          {item.endDate != null &&
                            <RadBox variant='awsui-key-label'>
                              End Date
                              <RadBox>{formatDate(item.endDate)}</RadBox>
                            </RadBox>}
                          {item.plannedStartDate != null &&
                            <RadBox variant='awsui-key-label'>
                              Planned Start Date
                              <RadBox>{formatDate(item.plannedStartDate)}</RadBox>
                            </RadBox>}
                          {item.plannedEndDate != null &&
                            <RadBox variant='awsui-key-label'>
                              Planned End Date
                              <RadBox>{formatDate(item.plannedEndDate)}</RadBox>
                            </RadBox>}
                          {item.laborRate != null &&
                            <RadBox variant='awsui-key-label'>
                              Labor Rate
                              <RadBox>${item.laborRate}</RadBox>
                            </RadBox>}
                          {item.totalHours != null &&
                            <RadBox variant='awsui-key-label'>
                              Total Hours
                              <RadBox>${item.totalHours}</RadBox>
                            </RadBox>}
                          {item.supportTypeId != null &&
                            <RadBox variant='awsui-key-label'>
                              Support Type
                              <RadBox>{item.supportType.name}</RadBox>
                            </RadBox>}
                          {item.serviceMarkup != null &&
                            <RadBox variant='awsui-key-label'>
                              Service Markup
                              <RadBox>{item.serviceMarkup} %</RadBox>
                            </RadBox>}
                          {item.spendCeilingTotal != null &&
                            <RadBox variant='awsui-key-label'>
                              Spend Ceiling Total
                              <RadBox>${item.spendCeilingTotal}</RadBox>
                            </RadBox>}
                          {item.spendCeilingMonthly != null &&
                            <RadBox variant='awsui-key-label'>
                              Spend Ceiling Monthly
                              <RadBox>${item.spendCeilingMonthly}</RadBox>
                            </RadBox>}
                          {item.statusId != null &&
                            <RadBox variant='awsui-key-label'>
                              Status
                              <RadBox>{item.status.name}</RadBox>
                            </RadBox>}
                          {item.notes != null &&
                            <RadBox variant='awsui-key-label'>
                              Notes
                              <RadBox>{item.notes}</RadBox>
                            </RadBox>}
                          {item.files.length > 0 &&
                            <RadBox variant='awsui-key-label'>
                              Files
                              <RadSpaceBetween size='xs'>
                                {item.files.map(x => <RadLink key={'service-file-' + x.file.id} href={x.file.link} target='_blank' rel='noreferrer'>{x.file.name}</RadLink>)}
                              </RadSpaceBetween>
                            </RadBox>}
                        </RadSpaceBetween>
                      )
                    }
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={project.services}
              variant='full-page'
            />
            {project.services.length === 0 &&
              <RadBox textAlign='center' padding={{ top: 'l' }}><RadBox>No services added to this contract.</RadBox></RadBox>}
          </RadSpaceBetween>
        }
      />
    )
  }
}
