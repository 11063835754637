import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadHeader } from '../common/RadHeader'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadButton } from '../common/RadButton'

export function PersonDetail () {
  const navigate = useNavigate()
  const { personId } = useParams()
  const { data: person } = useGet(`/api/person/${personId}`)
  const confirmDelete = useConfirm('Delete person?', 'Delete person permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/person/${personId}`, () => { navigate('/person') })

  if (person != null) {
    return (
      <RadAppLayout
        name={`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''} ${person.lastName}`}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={person.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''} ${person.lastName}`}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                  {person.photoUrl && <img className='profile' src={person.photoUrl} alt={`${person.firstName} ${person.preferredName ? '"' + person.preferredName + '" ' : ''}${person.lastName}`} referrerPolicy='no-referrer' />}
                </RadHeader>
              }
            >
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Full Name
                  </RadBox>
                  <div>{`${person.firstName} ${person.middleName ? person.middleName + ' ' : ''} ${person.lastName}`}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Email
                  </RadBox>
                  <div>{person.email ? <RadLink href={`mailto:${person.email}`}>{person.email}</RadLink> : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Phone
                  </RadBox>
                  <div>{person.phone ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Float ID
                  </RadBox>
                  <div>{person.floatId ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Organizations
                  </RadBox>
                  <div>{person.organizations.length > 0
                    ? person.organizations.map((x) => <div key={x.id}><RadLink href={`/organization/${x.organization.id}`}>{x.organization.commonName}</RadLink>{x.title != null ? ' - ' + x.title : ''}{x.primaryEmployer ? ' - Primary Employer' : ''}</div>)
                    : '-'}
                  </div>
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
