import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { RadBox } from './RadBox'
import { RadButton } from './RadButton'
import { RadCards } from './RadCards'
import { RadHeader } from './RadHeader'
import { RadLink } from './RadLink'
import { RadPagination } from './RadPagination'
import { RadSpaceBetween } from './RadSpaceBetween'
import { RadTable } from './RadTable'
import { RadTextFilter } from './RadTextFilter'
import { RadAppLayout } from './RadAppLayout'
import { getBreadcrumbName, titleCase } from './utilities'

export function RadAdaptiveList ({ entity, filters, fields, actions, emptyText, showActions = true, headerText }) {
  const pageLength = 20
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  let params = ''
  for (const entry of searchParams.entries()) {
    params += '&' + entry[0] + '=' + entry[1]
  }
  const { data: items, count, total } = useGet(`/api/${entity}?limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}` + params)

  const header = (field) => {
    if (typeof field === 'string') {
      if (field.includes('.')) {
        return field.split('.')[0]
      } else {
        return field
      }
    }
    return '-'
  }

  const content = (item, field) => {
    if (typeof field === 'string') {
      if (field === 'address') {
        return (
          <>
            <div>{item.address?.line1}</div>
            {item.address?.line2 && <div>{item.address?.line2}</div>}
            <div>{item.address?.city} {item.address?.state} {item.address?.zip}</div>
          </>
        )
      }
      if (field.includes('.')) {
        return field.split('.').reduce((o, i) => (o ? o[i] : null), item)
      } else {
        return item[field]
      }
    }
    if (typeof field.content === 'function') {
      return field.content(item)
    }
    if (field.link === true) {
      return <RadLink fontSize='inherit' href={`/${entity}/${item.id}`}>{item[field.name]}</RadLink>
    }
  }

  const definitions = () => {
    const results = []
    for (const field of fields) {
      results.push({
        id: header(field.header ?? field),
        header: field.header ?? titleCase(header(field)),
        content: item => content(item, field) ?? '-'
      })
    }
    if (!isMobile && showActions) {
      results.push({
        id: 'actions',
        content: item => <RadButton variant='inline-icon' iconName='edit' href={`/${entity}/${item.id}/edit?redirectURL=/${entity}`} />
      })
    }
    return results
  }

  function getCounter () {
    if (total == null) {
      return '(' + count + ')'
    } else {
      if (count === total) {
        return '(' + count + ')'
      } else {
        return '(' + count + ' of ' + total + ')'
      }
    }
  }

  const contentHeader = () => {
    return (
      <RadHeader
        variant='h1'
        counter={getCounter()}
        actions={showActions && (
          <RadSpaceBetween direction='horizontal' size='xs'>
            <RadButton onClick={() => navigate(`/${entity}/create`)}>Create</RadButton>
          </RadSpaceBetween>
        )}
      >
        {getBreadcrumbName(entity)}
      </RadHeader>
    )
  }

  const filter = (
    <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <RadTextFilter
          filteringPlaceholder='Search'
          filteringAriaLabel='Search'
          filteringText={filteringText}
          onChange={({ detail }) => setFilteringText(detail.filteringText)}
          onDelayedChange={({ detail }) => {
          // setSearchText(detail.filteringText)
            setCurrentPageIndex(1)
            searchParams.set('page', 1)
            if (detail.filteringText) {
              searchParams.set('search', detail.filteringText)
            } else {
              searchParams.delete('search')
            }
            setSearchParams(searchParams)
          }}
        />
      </div>
      {filters}
    </div>
  )

  const pagination = (
    <RadPagination
      currentPageIndex={currentPageIndex}
      pagesCount={Math.ceil(count / pageLength)}
      onChange={({ detail }) => {
        searchParams.set('page', detail.currentPageIndex)
        setSearchParams(searchParams)
        setCurrentPageIndex(detail.currentPageIndex)
      }}
      ariaLabels={{
        nextPageLabel: 'Next page',
        previousPageLabel: 'Previous page',
        pageLabel: pageNumber => `Page ${pageNumber} of all pages`
      }}
    />
  )

  const appContent = () => {
    if (isMobile) {
      return (
        <RadCards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item => (
              <RadBox fontWeight='bold' fontSize='heading-l' padding={{ bottom: 's' }}>
                {definitions()[0].content(item)}
              </RadBox>
            ),
            sections: definitions().filter((x, i) => i > 0) // .filter(x => x.card === true)
          }}
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 640, cards: 2 },
            { minWidth: 960, cards: 3 },
            { minWidth: 1280, cards: 4 }
          ]}
          items={items}
          filter={filter}
          pagination={pagination}
          variant='full-page'
          empty={empty}
        />
      )
    } else {
      return (
        <RadTable
          variant='full-page'
          items={items}
          columnDefinitions={definitions().map(x => ({ ...x, cell: x.content }))}
          filter={filter}
          pagination={pagination}
          empty={empty}
        />
      )
    }
  }

  const empty = (
    <RadBox textAlign='center' padding={{ top: 'l' }}>
      <RadBox>
        {emptyText ?? 'No matches found.'}
      </RadBox>
    </RadBox>
  )

  if (items != null) {
    return (
      <RadAppLayout
        contentHeader={contentHeader()}
        content={appContent()}
      />
    )
  }
}
