import React from 'react'
import './SignIn.scss'

export function SignIn () {
  return (
    <div className='sign-in'>
      <div className='content'>
        <h2>RAD_FRAMEWORK_SIGNIN_TITLE</h2>
        <span>RAD_FRAMEWORK_SIGNIN_TEXT</span>
      </div>
    </div>
  )
}
