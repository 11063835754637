
    import './styles.scoped.css';
    export default {
  "icon": "awsui_icon_h11ix_rho3r_185",
  "icon-flex-height": "awsui_icon-flex-height_h11ix_rho3r_193",
  "size-small": "awsui_size-small_h11ix_rho3r_218",
  "size-small-mapped-height": "awsui_size-small-mapped-height_h11ix_rho3r_222",
  "size-normal": "awsui_size-normal_h11ix_rho3r_237",
  "size-normal-mapped-height": "awsui_size-normal-mapped-height_h11ix_rho3r_241",
  "size-medium": "awsui_size-medium_h11ix_rho3r_256",
  "size-medium-mapped-height": "awsui_size-medium-mapped-height_h11ix_rho3r_260",
  "size-big": "awsui_size-big_h11ix_rho3r_275",
  "size-big-mapped-height": "awsui_size-big-mapped-height_h11ix_rho3r_279",
  "size-large": "awsui_size-large_h11ix_rho3r_294",
  "size-large-mapped-height": "awsui_size-large-mapped-height_h11ix_rho3r_298",
  "variant-normal": "awsui_variant-normal_h11ix_rho3r_313",
  "variant-disabled": "awsui_variant-disabled_h11ix_rho3r_316",
  "variant-inverted": "awsui_variant-inverted_h11ix_rho3r_319",
  "variant-subtle": "awsui_variant-subtle_h11ix_rho3r_322",
  "variant-warning": "awsui_variant-warning_h11ix_rho3r_325",
  "variant-error": "awsui_variant-error_h11ix_rho3r_328",
  "variant-success": "awsui_variant-success_h11ix_rho3r_331",
  "variant-link": "awsui_variant-link_h11ix_rho3r_334",
  "name-angle-left-double": "awsui_name-angle-left-double_h11ix_rho3r_337",
  "name-angle-left": "awsui_name-angle-left_h11ix_rho3r_337",
  "name-angle-right-double": "awsui_name-angle-right-double_h11ix_rho3r_339",
  "name-angle-right": "awsui_name-angle-right_h11ix_rho3r_339",
  "name-arrow-left": "awsui_name-arrow-left_h11ix_rho3r_341",
  "name-caret-left-filled": "awsui_name-caret-left-filled_h11ix_rho3r_342",
  "name-caret-right-filled": "awsui_name-caret-right-filled_h11ix_rho3r_343",
  "name-audio-full": "awsui_name-audio-full_h11ix_rho3r_344",
  "name-audio-half": "awsui_name-audio-half_h11ix_rho3r_345",
  "name-audio-off": "awsui_name-audio-off_h11ix_rho3r_346",
  "name-external": "awsui_name-external_h11ix_rho3r_347",
  "name-redo": "awsui_name-redo_h11ix_rho3r_348",
  "name-resize-area": "awsui_name-resize-area_h11ix_rho3r_349",
  "name-send": "awsui_name-send_h11ix_rho3r_350",
  "name-shrink": "awsui_name-shrink_h11ix_rho3r_351",
  "name-undo": "awsui_name-undo_h11ix_rho3r_352",
  "name-view-vertical": "awsui_name-view-vertical_h11ix_rho3r_353",
  "badge": "awsui_badge_h11ix_rho3r_376"
};
  