import React from 'react'
import { RadAdaptiveList } from '../common/RadAdaptiveList'

export function OrganizationList () {
  return (
    <RadAdaptiveList
      entity='organization'
      fields={[
        { header: 'Common Name', name: 'commonName', link: true },
        'acronym',
        'fullName',
        'parent.commonName'
      ]}
    />
  )
}
