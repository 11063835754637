import React, { useState } from 'react'
import FileUpload from '@cloudscape-design/components/file-upload'

// https://cloudscape.design/components/file-upload

export function RadFileUpload ({ onChange, ...rest }) {
  const [error, setError] = useState()
  return (
    <FileUpload
      onChange={async ({ detail }) => {
        const validFiles = detail.value.filter(x => x.size < 25000000)
        if (validFiles.length !== detail.value.length) {
          setError('File size must be less than 25MB')
          onChange({ detail: { value: validFiles } })
        } else {
          setError()
          onChange({ detail })
        }
      }}
      errorText={error}
      {...rest}
    />
  )
}
