
    import './styles.scoped.css';
    export default {
  "flash-with-motion": "awsui_flash-with-motion_1q84n_7e0ai_153",
  "enter": "awsui_enter_1q84n_7e0ai_153",
  "flash-body": "awsui_flash-body_1q84n_7e0ai_167",
  "flash-message": "awsui_flash-message_1q84n_7e0ai_167",
  "flash-header": "awsui_flash-header_1q84n_7e0ai_167",
  "flash-content": "awsui_flash-content_1q84n_7e0ai_168",
  "action-button-wrapper": "awsui_action-button-wrapper_1q84n_7e0ai_169",
  "dismiss-button-wrapper": "awsui_dismiss-button-wrapper_1q84n_7e0ai_170",
  "flash-icon": "awsui_flash-icon_1q84n_7e0ai_193",
  "entering": "awsui_entering_1q84n_7e0ai_206",
  "entered": "awsui_entered_1q84n_7e0ai_227",
  "exiting": "awsui_exiting_1q84n_7e0ai_332",
  "stack": "awsui_stack_1q84n_7e0ai_366",
  "animation-running": "awsui_animation-running_1q84n_7e0ai_366",
  "item": "awsui_item_1q84n_7e0ai_366",
  "flash-list-item": "awsui_flash-list-item_1q84n_7e0ai_367",
  "notification-bar": "awsui_notification-bar_1q84n_7e0ai_368",
  "collapsed": "awsui_collapsed_1q84n_7e0ai_388",
  "animation-ready": "awsui_animation-ready_1q84n_7e0ai_388",
  "expanded-only": "awsui_expanded-only_1q84n_7e0ai_388",
  "expanded": "awsui_expanded_1q84n_7e0ai_388",
  "flash": "awsui_flash_1q84n_7e0ai_153",
  "collapsible": "awsui_collapsible_1q84n_7e0ai_444",
  "short-list": "awsui_short-list_1q84n_7e0ai_450",
  "visual-refresh": "awsui_visual-refresh_1q84n_7e0ai_450",
  "status": "awsui_status_1q84n_7e0ai_597",
  "header": "awsui_header_1q84n_7e0ai_597",
  "item-count": "awsui_item-count_1q84n_7e0ai_598",
  "button": "awsui_button_1q84n_7e0ai_599",
  "type-count": "awsui_type-count_1q84n_7e0ai_632",
  "count-number": "awsui_count-number_1q84n_7e0ai_632",
  "icon": "awsui_icon_1q84n_7e0ai_666",
  "floating": "awsui_floating_1q84n_7e0ai_707",
  "flashbar": "awsui_flashbar_1q84n_7e0ai_711",
  "flash-refresh": "awsui_flash-refresh_1q84n_7e0ai_786",
  "flash-list": "awsui_flash-list_1q84n_7e0ai_367",
  "flash-focus-container": "awsui_flash-focus-container_1q84n_7e0ai_807",
  "flash-text": "awsui_flash-text_1q84n_7e0ai_837",
  "dismiss-button": "awsui_dismiss-button_1q84n_7e0ai_170",
  "breakpoint-default": "awsui_breakpoint-default_1q84n_7e0ai_879",
  "action-button": "awsui_action-button_1q84n_7e0ai_169",
  "action-slot": "awsui_action-slot_1q84n_7e0ai_889",
  "flash-type-success": "awsui_flash-type-success_1q84n_7e0ai_893",
  "flash-type-error": "awsui_flash-type-error_1q84n_7e0ai_897",
  "flash-type-info": "awsui_flash-type-info_1q84n_7e0ai_901",
  "flash-type-in-progress": "awsui_flash-type-in-progress_1q84n_7e0ai_902",
  "flash-type-warning": "awsui_flash-type-warning_1q84n_7e0ai_906"
};
  