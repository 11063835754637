import React from 'react'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'

export function ReportingLinks() {
  return (
    <RadAppLayout
      contentHeader={
        <RadHeader
          variant='h1'
        >
          Reporting Links
        </RadHeader>
      }
      content={
        <RadSpaceBetween size='l'>
          <RadContainer
            header={
              <RadHeader
                variant='h2'
              >
                Ops Hub Reporting
              </RadHeader>
            }
          >
            <RadColumnLayout columns={1} borders='vertical'>
              <div>
                <a href='https://lookerstudio.google.com/reporting/c49ddb0a-8290-4af7-8c36-eb6b2a9f4bea/page/p_td7qq5rkzc' target='_blank' rel='noreferrer'>Invoice List</a><br />
                This report lists all invoices and their status. It allows a user to filter by client, date range, contract, and service line.
              </div>
              <div>
                <a href='https://lookerstudio.google.com/reporting/c49ddb0a-8290-4af7-8c36-eb6b2a9f4bea/page/p_5z5h17y5dd' target='_blank' rel='noreferrer'>Contracts List</a><br />
                This report lists all contracts and their status.
              </div>
              <div>
                <a href='https://lookerstudio.google.com/reporting/c49ddb0a-8290-4af7-8c36-eb6b2a9f4bea/page/p_fnj7thy5dd' target='_blank' rel='noreferrer'>Contracts & Services List</a><br />
                This report lists all contracts, services, and their status.
              </div>
              <div>
                <a href='https://lookerstudio.google.com/reporting/c49ddb0a-8290-4af7-8c36-eb6b2a9f4bea/page/p_oxhck7y5dd' target='_blank' rel='noreferrer'>Contracts & Services List</a><br />
                This report lists all contracts and the total service value for each service line.
              </div>
            </RadColumnLayout>
          </RadContainer>
          <RadContainer
            header={
              <RadHeader
                variant='h2'
              >
                Capacity Reporting
              </RadHeader>
            }
          >
            <RadColumnLayout columns={1} borders='vertical'>
              <div>
                <a href='https://lookerstudio.google.com/reporting/69a897cb-ff64-42a4-84be-b0ff6d95f1cd/page/p_4d0b9jg4dd' target='_blank' rel='noreferrer'>Utilization & Availability</a><br />
                This report lists all team members and their utilization and availability by month.
              </div>
              <div>
                <a href='https://lookerstudio.google.com/reporting/69a897cb-ff64-42a4-84be-b0ff6d95f1cd/page/jxr3C' target='_blank' rel='noreferrer'>Project Utilization</a><br />
                This report lists the utilization of each team member by project.
              </div>
              <div>
                <a href='https://lookerstudio.google.com/reporting/69a897cb-ff64-42a4-84be-b0ff6d95f1cd/page/p_h96e2a8cbd' target='_blank' rel='noreferrer'>Hours Available</a><br />
                This report lists hours available by team member and month.
              </div>
              <div>
                <a href='https://lookerstudio.google.com/reporting/69a897cb-ff64-42a4-84be-b0ff6d95f1cd/page/p_e8ffa9ka1c' target='_blank' rel='noreferrer'>Utilization Targets</a><br />
                This report lists team member utilization targets.
              </div>
              <div>
                <a href='https://lookerstudio.google.com/u/0/reporting/69a897cb-ff64-42a4-84be-b0ff6d95f1cd/page/p_t6y6bg64yc' target='_blank' rel='noreferrer'>Logged Time</a><br />
                This report lists logged time, total, and billable utilization for each team member for a given date range.
              </div>
            </RadColumnLayout>
          </RadContainer>
        </RadSpaceBetween>
      }
    />
  )
}
