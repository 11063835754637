import { useContext } from 'react'
import { AppContext } from './../App'
import { removeEmptyStrings } from '../common/utilities'

export function usePost (uri, data, onSuccess) {
  const { user, setLoadingCount, reloadCounter, setReloadCounter, setError, setNotFound, setPermissionDenied } = useContext(AppContext)

  async function post () {
    setLoadingCount(count => count + 1)
    setError(null)
    const token = await user.getIdToken()
    const response = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(removeEmptyStrings(data))
    })
    let json = {}
    let text = ''
    switch (response.status) {
      case 200:
        try { json = await response.json() } catch { }
        setReloadCounter(reloadCounter + 1)
        setError(null)
        setNotFound(false)
        setPermissionDenied(null)
        onSuccess(json)
        break
      case 400:
        try { json = await response.json() } catch { }
        setError({ ...json, code: 'Validation Error' })
        break
      case 403:
        try { text = await response.text() } catch { }
        setError({ code: 'Permission Denied', message: text })
        break
      case 404:
        setError({ code: 'Not Found' }) // Chipi could be a bad uri, could be the think your trying to update is missing
        break
      case 500:
        try { json = await response.json() } catch { }
        if (json.name === 'SequelizeForeignKeyConstraintError') {
          setError({ code: 'Unprocessable', message: 'Unable to delete. Resource is in use.' })
          // Chipi - id rather get a 422 from the server
        } else if (json.name === 'SequelizeUniqueConstraintError') {
          setError({ code: 'Unprocessable', message: json.errors[0].message })
        } else {
          setError({ code: 'Error', message: 'Please contact administrator.' })
        }
        break
      default:
        setError({ code: response.statusText })
    }
    setLoadingCount(count => count - 1)
  }

  return post
}
