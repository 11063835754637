import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { RadMultiselect } from './RadMultiselect'

export function RadMultiselectFilter ({ name, param, options }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const value = searchParams.get(param) ?? ''

  return (
    <RadMultiselect
      selectedOptions={options.filter((x) => value.split('-').includes(x.value))}
      onChange={({ detail }) => {
        const ids = detail.selectedOptions.map((x) => x.value).join('-')
        if (ids === '') {
          searchParams.delete(param)
        } else {
          searchParams.set(param, ids)
        }
        setSearchParams(searchParams)
      }}
      options={options}
      selectedAriaLabel='Selected'
      deselectAriaLabel={e => `Remove ${e.label}`}
      placeholder={'Choose ' + name}
      keepOpen={false}
    />
  )
}
