import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import * as pluralize from 'pluralize'
import { toTitleCase } from 'titlecase'
import { AppContext } from './../App'
import { RadSideNavigation } from './RadSideNavigation'
import { RadBreadcrumbGroup } from './RadBreadcrumbGroup'
import AppLayout from '@cloudscape-design/components/app-layout'
import Flashbar from '@cloudscape-design/components-themed/flashbar'

export function RadAppLayout ({ notifications, ...rest }) {
  const location = useLocation()
  const { navigationOpen, setNavigationOpen } = useContext(AppContext)

  let notificationItems = []
  if (notifications != null && notifications.length > 0) {
    notificationItems = notificationItems.concat(notifications)
  }

  function breadcrumbs () {
    const items = []
    let acc = ''

    items.push({ text: 'Home', href: '/' })
    const paths = location.pathname.split('/')
    paths.splice(0, 1)

    const isEditing = paths[paths.length - 1] === 'edit'
    const isResults = paths[paths.length - 1] === 'results'
    const isCreating = paths[paths.length - 1] === 'create'

    if (isCreating) {
      paths.pop()
      paths.forEach((x) => {
        acc += '/' + x
        items.push({ text: pluralize(toTitleCase(x)).replaceAll('-', ' '), href: acc })
      })
      items.push({ text: 'Create' })
    } else if (isEditing) {
      paths.pop()
      paths.forEach((x) => {
        acc += '/' + x
        items.push({ text: x !== paths[paths.length - 1] ? pluralize(toTitleCase(x)).replaceAll('-', ' ') : rest.name ?? x, href: acc })
      })
      items.push({ text: 'Edit' })
    } else if (isResults) {
      paths.pop()
      paths.forEach((x) => {
        acc += '/' + x
        items.push({ text: x !== paths[paths.length - 1] ? pluralize(toTitleCase(x)).replaceAll('-', ' ') : rest.name ?? x, href: acc })
      })
      items.push({ text: 'Results' })
    } else {
      paths.forEach((x) => {
        acc += '/' + x
        items.push({ text: x !== paths[paths.length - 1] ? pluralize(toTitleCase(x)).replaceAll('-', ' ') : rest.name ?? pluralize(toTitleCase(x)).replaceAll('-', ' '), href: acc })
      })
    }

    return (
      <RadBreadcrumbGroup
        items={items}
        ariaLabel='Breadcrumbs'
      />
    )
  }

  if (rest.breadcrumbs == null) {
    rest.breadcrumbs = breadcrumbs()
  }

  if (rest.navigation == null) {
    rest.navigation = <RadSideNavigation setNavigationOpen={setNavigationOpen} />
  }

  // https://cloudscape.design/components/app-layout
  return (
    <AppLayout
      ariaLabels={{
        navigation: 'Navigation',
        navigationClose: 'Navigation close',
        navigationToggle: 'Navigation toggle',
        notifications: 'Notifications',
        tools: 'Tools',
        toolsClose: 'Tools close',
        toolsToggle: 'Tools toggle'
      }}
      {...rest}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      notifications={<Flashbar items={notificationItems} />}
      toolsHide
    />
  )
}
