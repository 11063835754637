import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadMultiselectFilter } from '../common/RadMultiselectFilter'
import { formatCurrency, createCommaSeparatedListServices } from '../common/utilities'

export function ContractList () {
  const { data: statusOptions } = useGet('/api/option/type?entity=contractStatus&required')

  if (statusOptions != null) {
    return (
      <RadAdaptiveList
        entity='contract'
        filters={
          <RadMultiselectFilter styles={{ flexGrow: 0 }} name='status' param='statusIds' options={statusOptions} />
        }
        fields={[
          { header: 'Number', name: 'number', link: true },
          { header: 'Name', name: 'name', link: true },
          'client.commonName',
          'funder.commonName',
          'effectiveDate',
          'expirationDate',
          { header: 'Services', content: (item) => createCommaSeparatedListServices(item.services) },
          { header: 'Contracted Total Value', content: (item) => formatCurrency(item.totalValue) }
        ]}
      />
    )
  }
}
