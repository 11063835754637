import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadMultiselectFilter } from '../common/RadMultiselectFilter'
import { RadLink } from '../common/RadLink'
import { formatCurrency } from '../common/utilities'

export function HostingList() {
  const { data: statusOptions } = useGet('/api/option/type?entity=serviceStatus&required')

  if (statusOptions != null) {
    return (
      <RadAdaptiveList
        entity='hosting'
        filters={
          <RadMultiselectFilter styles={{ flexGrow: 0 }} name='status' param='statusIds' options={statusOptions} />
        }
        showActions={false}
        fields={[
          { header: 'Client', content: (item) => <RadLink href={`/organization/${item.client_id}`}>{item.client}</RadLink> },
          { header: 'License', content: (item) => <RadLink href={`/contract/${item.contract_id}`}>{item.service_name}</RadLink> },
          'start_date',
          'end_date',
          { header: 'Amount', content: (item) => formatCurrency( item.total_value) },
          'status'
          // 'dateToBeSent',
          // { header: 'Client', content: (item) => <RadLink href={`/organization/${item.contract.client.id}`}>{item.contract.client.commonName}</RadLink>},
          // { header: 'Funder', content: (item) => <RadLink href={`/organization/${item.contract.funder.id}`}>{item.contract.funder.commonName}</RadLink>},
          // 'invoiceStatus.name'
        ]}
      />
    )
  }
}
