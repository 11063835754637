import React, { useState } from 'react'
import { useGet } from '../hooks/useGet'
import { Avatar } from '@rmwc/avatar'
import { RadBox } from '../common/RadBox'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDateTime, formatDate } from '../common/utilities'
import './Chat.scss'

export function Chat ({ messages, suggestions, onEnter, percentComplete, approximately }) {
  const { data: userInfo } = useGet('/api/user/current')

  function binaryStringToBase64 (binaryString) {
    let binary = ''
    for (let i = 0; i < binaryString.length; i++) {
      binary += String.fromCharCode(binaryString.charCodeAt(i) & 0xff)
    }
    return 'data:image/png;base64,' + btoa(binary)
  }

  if (
    userInfo != null
  ) {
    return (
      <div className='mio'>
        <div className='chat'>
          <RadSpaceBetween size='l'>
            {messages.map(message =>
              <div key={message.id}>
                <div className={message.role}>
                  {message.role === 'assistant' &&
                    <Avatar
                      src='../mio.png'
                      size='large'
                      name='MIO'
                      referrerPolicy='no-referrer'
                    />}
                  {message.role === 'user' &&
                    <Avatar
                      size='large'
                      name={userInfo.name}
                      referrerPolicy='no-referrer'
                    />}
                  <div className='message'>
                    {message.content.map((content, index) => {
                      if (content.type === 'text') {
                        const text = content.text?.value ?? content.text
                          .replace(/\[(.*?)\]/g, '').replace(/\n{3}/g, '\n').trim()
                        return (
                          <RadLineBreakRenderer
                            key={`${message.id}-content-${index}`}
                            className={content.type}
                            text={text}
                          />
                        )
                      }
                      if (content.type === 'image_file') {
                        return <img key={`${message.id}-content-${index}`} src={binaryStringToBase64(content.file)} />
                      }
                      return null
                    })}
                  </div>
                </div>
                <div className={`${message.role} time`}>{formatDateTime(message.createdAt ?? new Date()).replace(formatDate(Date.now()), '')}</div>
              </div>
            )}
            {messages.length > 0 && messages[messages.length - 1].role === 'user' &&
              <div className='assistant'>
                <Avatar
                  src='../mio.png'
                  size='large'
                  name='MIO'
                  referrerPolicy='no-referrer'
                />
                <div className='typing'>
                  <div className='typing__dot' />
                  <div className='typing__dot' />
                  <div className='typing__dot' />
                </div>
              </div>}
            <Input onEnter={onEnter} suggestions={suggestions} processing={messages.length > 0 && messages[messages.length - 1].role === 'user'} />
          </RadSpaceBetween>
        </div>
      </div>
    )
  }
}

function Input ({ initialValue, onEnter, suggestions, processing }) {
  const [value, setValue] = useState(initialValue ?? '')

  return (
    <div>
      <div className='input'>
        <textarea
          disabled={processing}
          inputMode='text'
          type='text'
          placeholder='Enter message'
          value={value}
          onChange={(event) => {
            const value = event.target.value
            setValue(value)
            if (value.trim() === '') { setValue('') }
          }}
          onKeyDown={(x) => {
            if (x.key === 'Enter' && !x.shiftKey) {
              onEnter({ text: value })
              setValue('')
            }
          }}
        />
        <button
          disabled={processing}
          className='material-symbols-outlined'
          onClick={() => {
            if (value.trim() !== '') {
              onEnter({ text: value })
              setValue('')
            }
          }}
        >
          send
        </button>
      </div>
      {suggestions.length > 0 &&
        <RadBox padding={{ top: 'xxs' }}>
          <div className='suggestions'>
            <span>Suggestions:</span>
            <div>
              {suggestions.map((x, index) =>
                <button
                  key={index}
                  disabled={processing}
                  onClick={() => onEnter({ text: x })}
                >
                  {x}
                </button>
              )}
            </div>
          </div>
        </RadBox>}
    </div>
  )
}
