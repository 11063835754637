import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from './../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadFileUpload } from '../common/RadFileUpload'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { formatCurrency, toBase64 } from '../common/utilities'

export function ContractEdit() {
  const navigate = useNavigate()
  const { contractId } = useParams()
  const [searchParams] = useSearchParams()
  const [files, setFiles] = useState([])
  const [formValues, setFormValues] = useState()
  const { data: contract } = useGet(contractId ? `/api/contract/${contractId}` : null)
  const { data: organizations } = useGet('/api/option/organization?required')
  const { data: serviceLines } = useGet('/api/service-line')
  const { data: chartOfAccountOptions } = useGet('/api/option/type?entity=chartOfAccount')
  const { data: contractTypeOptions } = useGet('/api/option/type?entity=contractType')
  const { data: contractStatusOptions } = useGet('/api/option/type?entity=contractStatus')
  const { data: invoiceApproverOptionsOptions } = useGet(`/api/option/invoice-approver?clientId=${formValues?.clientId ?? '0'}&funderId=${formValues?.funderId ?? '0'}`)
  // const { data: projectOptions } = useGet('/api/option/project')
  const { data: projects } = useGet('/api/project')
  const { data: organizationPersonOptions } = useGet(`/api/option/organization-person?organizationId=${formValues?.funderId ?? ''}`)
  const { data: revenueDesignationOptions } = useGet('/api/option/type?entity=revenueDesignation')
  const { data: statusOptions } = useGet('/api/option/type?entity=serviceStatus')
  const { data: supportTypeOptions } = useGet('/api/option/type?entity=supportType')
  const { data: changeOrderStatusOptions } = useGet('/api/option/type?entity=changeOrderStatus&required')
  const { data: changeOrderTypeOptions } = useGet('/api/option/type?entity=changeOrderType&required')
  const { data: milestoneTypeOptions } = useGet('/api/option/type?entity=milestoneType&required')
  const { data: invoiceStatusOptions } = useGet('/api/option/type?entity=invoiceStatus&required')

  useEffect(() => {
    if (contract != null) {
      setFiles(contract.files.map(x => new File([], x.file.name, { type: x.file.type })))
    }
    const defaultFormValues = {
      clientId: searchParams.get('clientId'),
      funderId: searchParams.get('funderId'),
      changeOrders: [],
      files: [],
      invoices: [],
      services: []
    }
    setFormValues(contract ?? defaultFormValues)
  }, [contract])

  const create = usePost('/api/contract', formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/contract/${resp.id}`) })
  const update = usePut(`/api/contract/${contractId}`, formValues, (resp) => { navigate(searchParams.get('redirectURL') ?? `/contract/${contractId}`) })

  async function saveChanges() {
    if (contractId) { update() } else { create() }
  }

  async function cancel() {
    if (contractId) {
      navigate(searchParams.get('redirectURL') ?? `/contract/${contractId}`)
    } else {
      navigate(searchParams.get('redirectURL') ?? '/contract')
    }
  }

  if (
    formValues != null &&
    organizations != null &&
    serviceLines != null &&
    chartOfAccountOptions != null &&
    contractTypeOptions != null &&
    contractStatusOptions != null &&
    invoiceApproverOptionsOptions != null &&
    projects != null &&
    revenueDesignationOptions != null &&
    statusOptions != null &&
    supportTypeOptions != null &&
    changeOrderStatusOptions != null &&
    organizationPersonOptions != null &&
    changeOrderTypeOptions != null &&
    milestoneTypeOptions &&
    invoiceStatusOptions
  ) {
    const hasMilestones = formValues.services.reduce((acc, x) => acc || x.milestones.length > 0, false)

    return (
      <RadAppLayout
        name={`Contract # ${contractId}`}
        contentHeader={
          <RadHeader variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
              </RadSpaceBetween>
            }
          >
            {formValues.id ? 'Edit Contract' : 'New Contract'}

          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='ID' field='number' optional>
                      <RadInput
                        placeholder='Enter ID'
                        ariaRequired
                        value={formValues.number ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, number: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Name' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Client' field='clientId'>
                      <RadSelect
                        filterType='auto'
                        selectedOption={formValues.clientId ? organizations.find(x => x.value === formValues.clientId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, clientId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, clientId: null })
                          }
                        }}
                        options={organizations}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Funder' field='funderId'>
                      <RadSelect
                        filterType='auto'
                        selectedOption={formValues.funderId ? organizations.find(x => x.value === formValues.funderId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, funderId: parseInt(detail.selectedOption.value), invoiceContactId: null })
                        }}
                        options={organizations}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Invoice Contact' field='invoiceContactId'>
                      <RadSelect
                        filterType='auto'
                        selectedOption={formValues.invoiceContactId ? organizationPersonOptions.find(x => x.value === formValues.invoiceContactId.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, invoiceContactId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, invoiceContactId: null })
                          }
                        }}
                        options={organizationPersonOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, xxs: 2 } },
                        { colspan: { default: 12, xxs: 2 } },
                        { colspan: { default: 12, xxs: 2 } }
                      ]}
                    >
                      <RadFormField label='Executed Date' field='executedDate'>
                        <RadInput
                          placeholder='Enter date'
                          ariaRequired
                          type='date'
                          value={formValues.executedDate ?? ''}
                          onChange={({ detail }) => setFormValues({ ...formValues, executedDate: detail.value })}
                          disabled={formValues.isProtected}
                        />
                      </RadFormField>
                      <RadFormField label='Effective Date' field='effectiveDate'>
                        <RadInput
                          placeholder='Enter date'
                          ariaRequired
                          type='date'
                          value={formValues.effectiveDate ?? ''}
                          onChange={({ detail }) => setFormValues({ ...formValues, effectiveDate: detail.value })}
                          disabled={formValues.isProtected}
                        />
                      </RadFormField>
                      <RadFormField label='Expiration Date' field='expirationDate' optional>
                        <RadInput
                          placeholder='Enter date'
                          ariaRequired
                          type='date'
                          value={formValues.expirationDate ?? ''}
                          onChange={({ detail }) => setFormValues({ ...formValues, expirationDate: detail.value })}
                          disabled={formValues.isProtected}
                        />
                      </RadFormField>
                    </RadGrid>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, xxs: 2 } },
                        { colspan: { default: 12, xxs: 2 } },
                        { colspan: { default: 12, xxs: 2 } }
                      ]}
                    >
                      <RadFormField label='Hourly Rate' field='hourlyRate' optional>
                        <RadInput
                          placeholder='Enter value'
                          ariaRequired
                          type='number'
                          value={formValues.hourlyRate ?? ''}
                          onChange={({ detail }) => setFormValues({ ...formValues, hourlyRate: detail.value })}
                          disabled={formValues.isProtected}
                        />
                      </RadFormField>
                      <RadFormField label='Contracted Total Value' field='totalValue'>
                        <RadInput
                          placeholder='Enter value'
                          ariaRequired
                          type='number'
                          value={formValues.totalValue ?? ''}
                          onChange={({ detail }) => setFormValues({ ...formValues, totalValue: detail.value })}
                          disabled={formValues.isProtected}
                        />
                      </RadFormField>
                      <RadFormField label='Status' field='contractStatusId'>
                        <RadSelect
                          selectedOption={formValues.contractStatusId ? contractStatusOptions.find(x => x.value === formValues.contractStatusId?.toString()) : { value: '', label: '-' }}
                          onChange={({ detail }) => {
                            if (detail.selectedOption.value !== '') {
                              setFormValues({ ...formValues, contractStatusId: parseInt(detail.selectedOption.value) })
                            } else {
                              setFormValues({ ...formValues, contractStatusId: null })
                            }
                          }}
                          options={contractStatusOptions}
                          selectedAriaLabel='Selected'
                          empty='No matches found'
                        />
                      </RadFormField>
                    </RadGrid>
                    <RadFormField label='Invoicing Terms' field='invoicingTerms' optional>
                      <RadTextarea
                        placeholder='Enter terms'
                        ariaRequired
                        value={formValues.invoicingTerms ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, invoicingTerms: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Payment Terms' field='paymentTerms' optional>
                      <RadTextarea
                        placeholder='Enter terms'
                        ariaRequired
                        value={formValues.paymentTerms ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, paymentTerms: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Amount Invoiced' field='amountInvoiced' optional>
                      <RadInput
                        placeholder='Enter value'
                        ariaRequired
                        type='number'
                        value={formValues.amountInvoiced ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, amountInvoiced: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Notes' field='notes' optional>
                      <RadTextarea
                        placeholder='Enter notes'
                        ariaRequired
                        value={formValues.notes ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, notes: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Services
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    {formValues.services.length === 0 && <RadBox>No services added to this contract.</RadBox>}
                    {formValues.services.map((x) =>
                      <ServiceEditor
                        key={`service-${x.id},${x.uuid}`}
                        item={x}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        serviceLines={serviceLines}
                        chartOfAccountOptions={chartOfAccountOptions}
                        contractTypeOptions={contractTypeOptions}
                        invoiceApproverOptions={invoiceApproverOptionsOptions}
                        projects={projects}
                        revenueDesignationOptions={revenueDesignationOptions}
                        statusOptions={statusOptions}
                        supportTypeOptions={supportTypeOptions}
                        milestoneTypeOptions={milestoneTypeOptions}
                        invoiceStatusOptions={invoiceStatusOptions}
                      />)}
                    <RadButton
                      onClick={() => {
                        const services = formValues.services
                        services.push({ uuid: uuidv4(), files: [], milestones: [] })
                        setFormValues({ ...formValues, services })
                      }}
                    >
                      Add new service
                    </RadButton>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Change Orders
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    {formValues.changeOrders.length === 0 && <RadBox>No change orders added to this contract.</RadBox>}
                    {formValues.changeOrders.map((x) =>
                      <ChangeOrderEditor
                        key={`change-order-${x.id},${x.uuid}`}
                        item={x}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        changeOrderStatusOptions={changeOrderStatusOptions}
                        changeOrderTypeOptions={changeOrderTypeOptions}
                        milestoneTypeOptions={milestoneTypeOptions}
                      />)}
                    <RadButton
                      onClick={() => {
                        const changeOrders = formValues.changeOrders
                        changeOrders.push({ uuid: uuidv4(), files: [] })
                        setFormValues({ ...formValues, changeOrders })
                      }}
                    >
                      Add new change order
                    </RadButton>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Invoices
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    {formValues.invoices.length === 0 && <RadBox color='text-status-inactive'>No invoices added to this contract.</RadBox>}
                    {formValues.invoices.map((x) =>
                      <InvoiceEditor
                        key={`invoice-${x.id},${x.uuid}`}
                        item={x}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        invoiceStatusOptions={invoiceStatusOptions}
                      />)}
                    <RadButton
                      onClick={() => {
                        const invoices = formValues.invoices
                        invoices.push({ uuid: uuidv4(), items: [] })
                        setFormValues({ ...formValues, invoices })
                      }}
                      disabled={!hasMilestones}
                    >
                      Add new invoice
                    </RadButton>
                    {!hasMilestones && <RadBox color='text-status-inactive'>* not available until milestones are added.</RadBox>}
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Files
                    </RadHeader>
                  }
                >
                  <RadFormField field='file'>
                    <RadFileUpload
                      multiple
                      onChange={async ({ detail }) => {
                        setFiles(detail.value)
                        setFormValues({
                          ...formValues,
                          files: await Promise.all(detail.value.map(async x => ({ name: x.name, type: x.type, data: await toBase64(x), file: { name: x.name, type: x.type } })))
                        })
                      }}
                      value={files}
                      i18nStrings={{
                        uploadButtonText: e => e ? 'Choose files' : 'Choose file',
                        dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
                        removeFileAriaLabel: e => `Remove file ${e + 1}`,
                        limitShowFewer: 'Show fewer files',
                        limitShowMore: 'Show more files',
                        errorIconAriaLabel: 'Error'
                      }}
                      showFileThumbnail={false}
                      tokenLimit={10}
                    />
                  </RadFormField>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function ServiceEditor({
  item,
  formValues,
  setFormValues,
  serviceLines,
  invoiceApproverOptions,
  projects,
  chartOfAccountOptions,
  contractTypeOptions,
  revenueDesignationOptions,
  statusOptions,
  supportTypeOptions,
  milestoneTypeOptions
}) {
  const [files, setFiles] = useState(item.files.map(x => new File([], x.file.name, { type: x.file.type })))
  const serviceLineOptions = serviceLines.map(x => ({ value: x.id, label: x.name }))
  const serviceOptions = (serviceLines.find(x => x.id === item.service?.serviceLineId)?.services ?? [])
    .map(x => ({ value: x.id, label: x.name }))
  const selectedService = serviceLines.find(x => x.id === item.service?.serviceLineId)?.services?.find(x => x.id === item.serviceId)
  const filteredProjects = projects
    .filter(x => x?.clientId === formValues?.clientId) ?? []
  const projectOptions = filteredProjects.map(project => ({ value: project.id, label: project.name }));
  projectOptions.unshift({ value: '', label: '-' })
  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, m: 2 } },
          { colspan: { default: 12, m: 3 } },
          { colspan: { default: 12, m: 3 } },
          { colspan: { default: 12, m: 3 } }
        ]}
      >
        <RadFormField label='ID' optional>
          <RadInput
            type='number'
            placeholder='Enter ID'
            value={item.number}
            onChange={({ detail }) => {
              const services = formValues.services
              item.number = detail.value
              setFormValues({ ...formValues, services })
            }}
          />
        </RadFormField>
        <RadFormField label='Service Line'>
          <RadSelect
            filterType='auto'
            selectedOption={item.service?.serviceLineId ? serviceLineOptions.find(x => x.value === item.service?.serviceLineId) : { value: '', label: '-' }}
            onChange={({ detail }) => {
              const services = formValues.services
              item.service = item.service ?? {}
              item.service.serviceLineId = detail.selectedOption.value
              setFormValues({ ...formValues, services })
            }}
            options={serviceLineOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, m: 3 } },
          { colspan: { default: 12, m: 3 } }
        ]}
      >
        <RadFormField label='Service' field={`service.${item.id ?? item.uuid}.serviceId`}>
          <RadSelect
            filterType='auto'
            selectedOption={item.serviceId ? serviceOptions.find(x => x.value === item.serviceId) : { value: '', label: '-' }}
            onChange={({ detail }) => {
              const services = formValues.services
              item.serviceId = detail.selectedOption.value
              setFormValues({ ...formValues, services })
            }}
            options={serviceOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Project' field={`service.${item.id ?? item.uuid}.projectId`} optional>
          <RadSelect
            filterType='auto'
            selectedOption={item.projectId ? projectOptions.find(x => x.value === item.projectId) : { value: '', label: '-' }}
            onChange={({ detail }) => {
              const services = formValues.services
              item.projectId = detail.selectedOption.value
              setFormValues({ ...formValues, services })
            }}
            options={projectOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
      </RadGrid>
      <RadGrid>
        {selectedService?.chartOfAccountId != null &&
          <RadFormField label='Chart of Account' field={`service.${item.id ?? item.uuid}.chartOfAccountId`} optional={selectedService.chartOfAccountId === 'optional'}>
            <RadSelect
              filterType='auto'
              selectedOption={item.chartOfAccountId ? chartOfAccountOptions.find(x => x.value === item.chartOfAccountId.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                const services = formValues.services
                item.chartOfAccountId = detail.selectedOption.value
                setFormValues({ ...formValues, services })
              }}
              options={chartOfAccountOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>}
        {selectedService?.contractTypeId != null &&
          <RadFormField label='Contract Type' field={`service.${item.id ?? item.uuid}.contractTypeId`} optional={selectedService.contractTypeId === 'optional'}>
            <RadSelect
              filterType='auto'
              selectedOption={item.contractTypeId ? contractTypeOptions.find(x => x.value === item.contractTypeId.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                const services = formValues.services
                item.contractTypeId = detail.selectedOption.value
                setFormValues({ ...formValues, services })
              }}
              options={contractTypeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>}
        {selectedService?.revenueDesignation != null &&
          <RadFormField label='Revenue Designation' field={`service.${item.id ?? item.uuid}.revenueDesignationId`} optional={selectedService.revenueDesignation === 'optional'}>
            <RadSelect
              filterType='auto'
              selectedOption={item.revenueDesignationId ? revenueDesignationOptions.find(x => x.value === item.revenueDesignationId.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                const services = formValues.services
                item.revenueDesignationId = detail.selectedOption.value
                setFormValues({ ...formValues, services })
              }}
              options={revenueDesignationOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>}
        {selectedService?.startDate != null &&
          <RadFormField label='Start Date' field={`service.${item.id ?? item.uuid}.startDate`} optional={selectedService.startDate === 'optional'}>
            <RadInput
              ariaRequired={selectedService.startDate === 'required'}
              type='date'
              value={item.startDate}
              onChange={({ detail }) => {
                const services = formValues.services
                item.startDate = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.endDate &&
          <RadFormField label='End Date' field={`service.${item.id ?? item.uuid}.endDate`} optional={selectedService.endDate === 'optional'}>
            <RadInput
              ariaRequired={selectedService.endDate === 'required'}
              type='date'
              value={item.endDate}
              onChange={({ detail }) => {
                const services = formValues.services
                item.endDate = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.totalValue &&
          <RadFormField label='Total Value' field={`service.${item.id ?? item.uuid}.totalValue`} optional={selectedService.totalValue === 'optional'}>
            <RadInput
              placeholder='Enter total value'
              ariaRequired={selectedService.totalValue === 'required'}
              value={item.totalValue}
              onChange={({ detail }) => {
                const services = formValues.services
                item.totalValue = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.invoiceApproverId != null &&
          <RadFormField label='Invoice Approver' field={`service.${item.id ?? item.uuid}.invoiceApproverId`} optional={selectedService.invoiceApprover === 'optional'}>
            <RadSelect
              filterType='auto'
              selectedOption={item.invoiceApproverId ? invoiceApproverOptions.find(x => x.value === item.invoiceApproverId.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                const services = formValues.services
                item.invoiceApproverId = detail.selectedOption.value
                setFormValues({ ...formValues, services })
              }}
              options={invoiceApproverOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>}
        {selectedService?.plannedStartDate &&
          <RadFormField label='Planned Start Date' field={`service.${item.id ?? item.uuid}.plannedStartDate`} optional={selectedService.plannedStartDate === 'optional'}>
            <RadInput
              ariaRequired={selectedService.plannedStartDate === 'required'}
              type='date'
              value={item.plannedStartDate}
              onChange={({ detail }) => {
                const services = formValues.services
                item.plannedStartDate = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.plannedEndDate &&
          <RadFormField label='Planned End Date' field={`service.${item.id ?? item.uuid}.plannedEndDate`} optional={selectedService.plannedEndDate === 'optional'}>
            <RadInput
              ariaRequired={selectedService.plannedEndDate === 'required'}
              type='date'
              value={item.plannedEndDate}
              onChange={({ detail }) => {
                const services = formValues.services
                item.plannedEndDate = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.laborRate &&
          <RadFormField label='Labor Rate' field={`service.${item.id ?? item.uuid}.laborRate`} optional={selectedService.laborRate === 'optional'}>
            <RadInput
              placeholder='Enter labor rate'
              ariaRequired={selectedService.laborRate === 'required'}
              value={item.laborRate}
              onChange={({ detail }) => {
                const services = formValues.services
                item.laborRate = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.totalHours &&
          <RadFormField label='Total Hours' field={`service.${item.id ?? item.uuid}.totalHours`} optional={selectedService.totalHours === 'optional'}>
            <RadInput
              placeholder='Enter total hours'
              ariaRequired={selectedService.totalHours === 'required'}
              value={item.totalHours}
              onChange={({ detail }) => {
                const services = formValues.services
                item.totalHours = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.supportTypeId != null &&
          <RadFormField label='Support Type' field={`service.${item.id ?? item.uuid}.supportTypeId`} optional={selectedService.supportTypeId === 'optional'}>
            <RadSelect
              filterType='auto'
              selectedOption={item.supportTypeId ? supportTypeOptions.find(x => x.value === item.supportTypeId.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                const services = formValues.services
                item.supportTypeId = detail.selectedOption.value
                setFormValues({ ...formValues, services })
              }}
              options={supportTypeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>}
        {selectedService?.serviceMarkup &&
          <RadFormField label='Service Markup Percentage' field={`service.${item.id ?? item.uuid}.serviceMarkup`} optional={selectedService.serviceMarkup === 'optional'}>
            <RadInput
              placeholder='Enter service markup percentage'
              ariaRequired={selectedService.serviceMarkup === 'required'}
              value={item.serviceMarkup}
              onChange={({ detail }) => {
                const services = formValues.services
                item.serviceMarkup = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.spendCeilingTotal &&
          <RadFormField label='Spend Ceiling Total' field={`service.${item.id ?? item.uuid}.spendCeilingTotal`} optional={selectedService.spendCeilingTotal === 'optional'}>
            <RadInput
              placeholder='Enter spend ceiling total'
              ariaRequired={selectedService.spendCeilingTotal === 'required'}
              value={item.spendCeilingTotal}
              onChange={({ detail }) => {
                const services = formValues.services
                item.spendCeilingTotal = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.spendCeilingMonthly &&
          <RadFormField label='Spend Ceiling Monthly' field={`service.${item.id ?? item.uuid}.spendCeilingMonthly`} optional={selectedService.spendCeilingMonthly === 'optional'}>
            <RadInput
              placeholder='Enter spend ceiling monthly'
              ariaRequired={selectedService.spendCeilingMonthly === 'required'}
              value={item.spendCeilingMonthly}
              onChange={({ detail }) => {
                const services = formValues.services
                item.spendCeilingMonthly = detail.value
                setFormValues({ ...formValues, services })
              }}
            />
          </RadFormField>}
        {selectedService?.statusId != null &&
          <RadFormField label='Status' field={`service.${item.id ?? item.uuid}.statusId`} optional={selectedService.statusId === 'optional'}>
            <RadSelect
              filterType='auto'
              selectedOption={item.statusId ? statusOptions.find(x => x.value === item.statusId.toString()) : { value: '', label: '-' }}
              onChange={({ detail }) => {
                const services = formValues.services
                item.statusId = detail.selectedOption.value
                setFormValues({ ...formValues, services })
              }}
              options={statusOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadFormField>}
      </RadGrid>
      <RadFormField label='Notes' field={`service.${item.id ?? item.uuid}.notes`} optional>
        <RadTextarea
          placeholder='Enter notes'
          ariaRequired
          rows={3}
          value={item.notes}
          onChange={({ detail }) => {
            const services = formValues.services
            item.notes = detail.value
            setFormValues({ ...formValues, services })
          }}
        />
      </RadFormField>

      <RadButton
        onClick={() => {
          const services = formValues.services
            .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
          setFormValues({ ...formValues, services })
        }}
      >
        Remove service
      </RadButton>

      <RadDivider noPadding />
      <RadHeader
        variant='h3'
        counter={'(' + item.milestones.length + ')'}
      >
        Milestones
      </RadHeader>
      <RadAttributeEditor
        onAddButtonClick={() => {
          const services = formValues.services
          item.milestones.push({ uuid: uuidv4() })
          setFormValues({ ...formValues, services })
        }}
        onRemoveButtonClick={({ detail: { itemIndex } }) => {
          const services = formValues.services
          item.milestones.splice(itemIndex, 1)
          setFormValues({ ...formValues, services })
        }}
        items={item.milestones}
        addButtonText='Add new milestone'
        removeButtonText='Remove milestone'
        definition={[
          {
            label: 'Name',
            control: item2 => {
              return (
                <RadFormField field={`service.${item.id ?? item.uuid}.milestone.${item2.id ?? item2.uuid}.name`}>
                  <RadInput
                    placeholder='Enter name'
                    ariaRequired
                    value={item2.name}
                    onChange={({ detail }) => {
                      const services = formValues.services
                      item2.name = detail.value
                      setFormValues({ ...formValues, services })
                    }}
                  />
                </RadFormField>
              )
            }
          },
          {
            label: 'Type',
            control: item2 => {
              return (
                <RadFormField field={`service.${item.id ?? item.uuid}.milestone.${item2.id ?? item2.uuid}.typeId`}>
                  <RadSelect
                    filterType='auto'
                    selectedOption={item2.typeId ? milestoneTypeOptions.find(x => x.value === item2.typeId.toString()) : { value: '', label: '-' }}
                    onChange={({ detail }) => {
                      const services = formValues.services
                      item2.typeId = detail.selectedOption.value
                      setFormValues({ ...formValues, services })
                    }}
                    options={milestoneTypeOptions}
                    selectedAriaLabel='Selected'
                    empty='No matches found'
                  />
                </RadFormField>
              )
            }
          },
          {
            label: 'Value',
            control: item2 => {
              return (
                <RadFormField field={`service.${item.id ?? item.uuid}.milestone.${item2.id ?? item2.uuid}.value`}>
                  <RadInput
                    placeholder='Enter value'
                    ariaRequired
                    type='number'
                    value={item2.value}
                    onChange={({ detail }) => {
                      const services = formValues.services
                      item2.value = detail.value
                      setFormValues({ ...formValues, services })
                    }}
                  />
                </RadFormField>
              )
            }
          }
        ]}
        empty='No milestones added to this service.'
      />

      <RadDivider noPadding />
      <RadHeader variant='h3'>
        Files
      </RadHeader>
      <RadFormField field='file'>
        <RadFileUpload
          multiple
          onChange={async ({ detail }) => {
            setFiles(detail.value)
            const services = formValues.services
            item.files = await Promise.all(detail.value.map(async x => ({ name: x.name, type: x.type, data: await toBase64(x), file: { name: x.name, type: x.type } })))
            setFormValues({ ...formValues, services })
          }}
          value={files}
          i18nStrings={{
            uploadButtonText: e => e ? 'Choose files' : 'Choose file',
            dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
            removeFileAriaLabel: e => `Remove file ${e + 1}`,
            limitShowFewer: 'Show fewer files',
            limitShowMore: 'Show more files',
            errorIconAriaLabel: 'Error'
          }}
          showFileThumbnail={false}
          tokenLimit={10}
        />
      </RadFormField>
      <RadDivider />
    </RadSpaceBetween>
  )
}

function ChangeOrderEditor({
  item,
  formValues,
  setFormValues,
  serviceLines,
  changeOrderStatusOptions,
  changeOrderTypeOptions
}) {
  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, m: 2 } },
          { colspan: { default: 12, m: 3 } },
          { colspan: { default: 12, m: 3 } }
        ]}
      >
        <RadFormField label='Date' field={`changeOrder.${item.id ?? item.uuid}.date`}>
          <RadInput
            type='date'
            value={item.date}
            onChange={({ detail }) => {
              const changeOrders = formValues.changeOrders
              item.date = detail.value
              setFormValues({ ...formValues, changeOrders })
            }}
          />
        </RadFormField>
        <RadFormField label='Status' field={`changeOrder.${item.id ?? item.uuid}.statusId`}>
          <RadSelect
            filterType='auto'
            selectedOption={changeOrderStatusOptions.find(x => x.value === item.statusId?.toString()) ?? { value: '', label: '-' }}
            onChange={({ detail }) => {
              const changeOrders = formValues.changeOrders
              item.statusId = detail.selectedOption.value
              setFormValues({ ...formValues, changeOrders })
            }}
            options={changeOrderStatusOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Type' field={`changeOrder.${item.id ?? item.uuid}.typeId`}>
          <RadSelect
            filterType='auto'
            selectedOption={changeOrderTypeOptions.find(x => x.value === item.typeId?.toString()) ?? { value: '', label: '-' }}
            onChange={({ detail }) => {
              const changeOrders = formValues.changeOrders
              item.typeId = detail.selectedOption.value
              setFormValues({ ...formValues, changeOrders })
            }}
            options={changeOrderTypeOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
      </RadGrid>
      <RadFormField label='Description' field={`changeOrder.${item.id ?? item.uuid}.description`}>
        <RadTextarea
          placeholder='Enter description'
          ariaRequired
          rows={3}
          value={item.description}
          onChange={({ detail }) => {
            const changeOrders = formValues.changeOrders
            item.description = detail.value
            setFormValues({ ...formValues, changeOrders })
          }}
        />
      </RadFormField>
      <RadButton
        onClick={() => {
          const changeOrders = formValues.changeOrders
            .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
          setFormValues({ ...formValues, changeOrders })
        }}
      >
        Remove change order
      </RadButton>
      <RadDivider />
    </RadSpaceBetween>
  )
}

function InvoiceEditor({
  item,
  formValues,
  setFormValues,
  invoiceStatusOptions
}) {
  return (
    <RadSpaceBetween size='l'>
      <RadFormField label='Title' field={`invoice.${item.id ?? item.uuid}.title`}>
        <RadInput
          type='title'
          value={item.title}
          onChange={({ detail }) => {
            const invoices = formValues.invoices
            item.title = detail.value
            setFormValues({ ...formValues, invoices })
          }}
        />
      </RadFormField>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, m: 3 } },
          { colspan: { default: 12, m: 3 } },
          { colspan: { default: 12, m: 3 } }
        ]}
      >
        <RadFormField label='Date Requested' field={`invoice.${item.id ?? item.uuid}.dateRequested`}>
          <RadInput
            ariaRequired
            type='date'
            value={item.dateRequested}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.dateRequested = detail.value
              setFormValues({ ...formValues, invoices })
            }}
          />
        </RadFormField>
        <RadFormField label='Date To Be Sent' field={`invoice.${item.id ?? item.uuid}.dateToBeSent`}>
          <RadInput
            ariaRequired
            type='date'
            value={item.dateToBeSent}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.dateToBeSent = detail.value
              setFormValues({ ...formValues, invoices })
            }}
          />
        </RadFormField>
        <RadFormField label='Invoice Status' field={`invoice.${item.id ?? item.uuid}.invoiceStatusId`}>
          <RadSelect
            filterType='auto'
            selectedOption={invoiceStatusOptions.find(x => x.value === item.invoiceStatusId?.toString()) ?? { value: '', label: '-' }}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.invoiceStatusId = detail.selectedOption.value
              setFormValues({ ...formValues, invoices })
            }}
            options={invoiceStatusOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
      </RadGrid>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, m: 4 } },
          { colspan: { default: 12, m: 4 } }
        ]}
      >
        <RadFormField label='Internal Notes' field={`invoice.${item.id ?? item.uuid}.internalNotes`} optional>
          <RadTextarea
            placeholder='Enter internal notes'
            ariaRequired
            rows={3}
            value={item.internalNotes}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.internalNotes = detail.value
              setFormValues({ ...formValues, invoices })
            }}
          />
        </RadFormField>
        <RadFormField label='External Notes' field={`invoice.${item.id ?? item.uuid}.externalNotes`} optional>
          <RadTextarea
            placeholder='Enter external notes'
            ariaRequired
            rows={3}
            value={item.externalNotes}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.externalNotes = detail.value
              setFormValues({ ...formValues, invoices })
            }}
          />
        </RadFormField>
      </RadGrid>
      <RadSpaceBetween size='l'>
        <RadBox variant='h3'>
          <RadDivider />
          Items <RadBox variant='h3' color='text-status-inactive' display='inline'>({item.items.length})</RadBox>
          {item.items.length === 0 && <RadBox color='text-status-inactive' padding={{ top: 's' }}>No items added to this invoice.</RadBox>}
        </RadBox>
        {item.items.map((x) =>
          <InvoiceItemEditor
            key={`invoice-${x.id},${x.uuid}`}
            invoice={item}
            item={x}
            formValues={formValues}
            setFormValues={setFormValues}
          />)}
        <RadButton
          onClick={() => {
            const invoices = formValues.invoices
            item.items.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, invoices })
          }}
        >
          Add new item
        </RadButton>
      </RadSpaceBetween>
      <RadButton
        onClick={() => {
          const invoices = formValues.invoices
            // invoices.items = invoices.items
            .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
          setFormValues({ ...formValues, invoices })
        }}
      >
        Remove invoice
      </RadButton>
      <RadDivider />
    </RadSpaceBetween>
  )
}

function InvoiceItemEditor({
  invoice,
  item,
  formValues,
  setFormValues
}) {
  const isMobile = useIsMobile()
  const milestoneOptions = formValues.services.reduce((acc, x) => {
    for (const milestone of x.milestones) {
      if (!acc.find(y => y.value === milestone.id || y.value === milestone.uuid)) {
        acc.push({ value: milestone.id || milestone.uuid, label: milestone.name })
      }
    }
    return acc
  }, [])

  function getSubTotal() {
    const quantity = parseFloat(item.quantity)
    const price = parseFloat(item.price)
    if (isNaN(quantity) || isNaN(price)) {
      return '-'
    } else {
      return formatCurrency(quantity * price)
    }
  }

  function getTotal() {
    let total = 0.0
    for (const item of invoice.items) {
      const quantity = parseFloat(item.quantity)
      const price = parseFloat(item.price)
      if (!isNaN(quantity) && !isNaN(price)) {
        total += quantity * price
      }
    }
    invoice.total = total
    return total > 0 ? formatCurrency(total) : '-'
  }

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, m: 2 } },
          { colspan: { default: 12, m: 1 } },
          { colspan: { default: 12, m: 4 } },
          { colspan: { default: 12, m: 2 } },
          { colspan: { default: 12, m: 1 } },
          { colspan: { default: 12, m: 2 } }
        ]}
      >
        <RadFormField label={isMobile || item === invoice.items[0] ? 'Milestone' : null} field={`invoice.${invoice.id ?? invoice.uuid}.item.${item.id ?? item.uuid}.contractServiceMilestoneId`}>
          <RadSelect
            filterType='auto'
            selectedOption={milestoneOptions.find(x => x.value === item.contractServiceMilestoneId) ?? { value: '', label: '-' }}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.contractServiceMilestoneId = detail.selectedOption.value
              setFormValues({ ...formValues, invoices })
            }}
            options={milestoneOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label={isMobile || item === invoice.items[0] ? 'Quantity' : null} field={`invoice.${invoice.id ?? invoice.uuid}.item.${item.id ?? item.uuid}.quantity`}>
          <RadInput
            type='number'
            value={item.quantity}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.quantity = detail.value
              setFormValues({ ...formValues, invoices })
            }}
          />
        </RadFormField>
        <RadFormField label={isMobile || item === invoice.items[0] ? 'Description' : null} field={`invoice.${invoice.id ?? invoice.uuid}.item.${item.id ?? item.uuid}.description`}>
          <RadInput
            value={item.description}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.description = detail.value
              setFormValues({ ...formValues, invoices })
            }}
          />
        </RadFormField>
        <RadFormField label={isMobile || item === invoice.items[0] ? 'Price' : null} field={`invoice.${invoice.id ?? invoice.uuid}.item.${item.id ?? item.uuid}.price`}>
          <RadInput
            type='number'
            value={item.price}
            onChange={({ detail }) => {
              const invoices = formValues.invoices
              item.price = detail.value
              setFormValues({ ...formValues, invoices })
            }}
          />
        </RadFormField>
        <RadBox variant='awsui-key-label' textAlign='right'>
          {isMobile || item === invoice.items[0] ? 'Subtotal' : null}
          <RadBox padding={{ top: 'xs' }}>{getSubTotal()}</RadBox>
          {item === invoice.items[invoice.items.length - 1] && getTotal() !== '-' &&
            <RadBox variant='awsui-key-label' padding={{ top: 'xs' }}><RadDivider />Total: {getTotal()}</RadBox>}
        </RadBox>
        <RadBox padding={{ top: isMobile || item === invoice.items[0] ? 'l' : null }}>
          <RadButton
            onClick={() => {
              const invoices = formValues.invoices
              invoice.items = invoice.items.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, invoices })
            }}
          >
            Remove item
          </RadButton>
        </RadBox>
      </RadGrid>
      {isMobile && <RadDivider />}
    </RadSpaceBetween>
  )
}
