import moment from 'moment'
import * as pluralizer from 'pluralize'

export const removeEmptyStrings = (obj) => {
  const isArray = Array.isArray(obj)
  for (const k of Object.keys(obj)) {
    if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'string' && obj[k].trim() === '') {
      obj[k] = null
    } else if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'object') {
      removeEmptyStrings(obj[k])
    }
    if (isArray && obj.length === k) {
      removeEmptyStrings(obj)
    }
  }
  return obj
}

export function formatDate (inputString, withTime = false) {
  if (inputString == null) {
    return null
  }
  const format = withTime ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY'
  return moment(inputString).format(format)
}

export function formatDateTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY h:mm A')
}

export function formatDateForInputs (inputString) {
  if (inputString == null) {
    return ''
  }
  const format = 'YYYY-MM-DD'
  return moment(inputString).format(format)
}

export function formatCurrency (value, locale = 'en-US', currency = 'USD') {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value)
}

export function formatNumber (number) {
  return number
    ? number
      .replace('+1', '')
      .replace(/[^0-9]/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    : null
}

export function getBreadcrumbName (value) {
  return pluralizer(toTitleCase(value))
    .replaceAll('-', ' ')
    .replaceAll('Apis', 'APIs')
}

export function getOrdinal (number) {
  switch (number) {
    case undefined:
      return ''
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export function isValidDate (inputString) {
  if (inputString == null) return false
  if (inputString.length !== 10) return false
  const date = moment(inputString, 'YYYY/MM/DD')
  return date.isValid()
}

export function toTitleCase (str) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export function getGrade (grade) {
  switch (grade) {
    case -2:
      return 'Daycare'
    case -1:
      return 'Preschool'
    case -0:
      return 'Kindergarten'
    default:
      return getOrdinal(grade) + ' grade'
  }
}

export function humanReadableId (id) {
  return id.toString().padStart(7, '0')
}

export function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function titleCase (value) {
  return toTitleCase(value)
}

export const toBase64 = file => new Promise((resolve, reject) => {
  if (file == null || file.size === 0) return resolve(null)
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
  reader.readAsDataURL(file)
})

export function createCommaSeparatedListServices (items) {
  if (!items || items.length === 0) {
    return '-'
  }
  return items.map(item => item.service.name).join(', ')
}

export function createCommaSeparatedListClients (project) {
  const items = project.services

  if (!items || items.length === 0) {
    return '-'
  }

  const clientNames = items
    .filter(item => item.contract && item.contract.clientOrganization)
    .map(item => item.contract.clientOrganization.commonName)

  const uniqueClientNames = [...new Set(clientNames)]

  return uniqueClientNames.join(', ')
}
