import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from './../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function ServiceLineEdit() {
  const navigate = useNavigate()
  const { serviceLineId } = useParams()
  const { data: serviceLine } = useGet(serviceLineId ? `/api/service-line/${serviceLineId}` : null)
  const [formValues, setFormValues] = useState()

  const confirmDelete = useConfirm('Delete service line?', 'Delete service line permanently? This action cannot be undone and will remove all service data mapped to it.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/service-line/${serviceLineId}`, () => { navigate('/service-line') })

  const create = usePost('/api/service-line', formValues, (resp) => { navigate(`/service-line`) })
  const update = usePut(`/api/service-line/${serviceLineId}`, formValues, (resp) => { navigate('/service-line') })

  useEffect(() => {
    const defaultFormValues = { services: [] }
    setFormValues(serviceLine ?? defaultFormValues)
  }, [serviceLine])

  async function saveChanges() {
    if (serviceLine) { update() } else { create() }
  }

  async function cancel() {
    if (serviceLineId) {
      navigate('/service-line')
    } else {
      navigate('/service-line')
    }
  }

  if (
    formValues != null
  ) {
    console.log(formValues.services)
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'
          actions={
            <RadSpaceBetween direction='horizontal' size='xs'>
              <RadButton onClick={confirmDelete}>Delete</RadButton>
            </RadSpaceBetween>
          }
          >
            {formValues.id ? 'Edit Service Line' : 'New Service Line'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Service Line Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name ?? ''}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                    >
                      Services
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    {formValues.services.length === 0 && <RadBox>No services added to this contract.</RadBox>}
                    {formValues.services.map((x) =>
                      <ServiceEditor
                        key={`service-${x.id},${x.uuid}`}
                        item={x}
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />)}
                    <RadButton
                      onClick={() => {
                        const services = formValues.services
                        services.push({ uuid: uuidv4() })
                        setFormValues({ ...formValues, services })
                      }}
                    >
                      Add new service
                    </RadButton>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function ServiceEditor({ item, formValues, setFormValues }) {
  const values = [
    { name: 'Chart of Account', field: 'chartOfAccountId' },
    { name: 'Contract Type', field: 'contractTypeId' },
    { name: 'Revenue Designation', field: 'revenueDesignationId' },
    { name: 'Start Date', field: 'startDate' },
    { name: 'End Date', field: 'endDate' },
    { name: 'Total Value', field: 'totalValue' },
    { name: 'Invoice Approver', field: 'invoiceApproverId' },
    { name: 'Planned Start Date', field: 'plannedStartDate' },
    { name: 'Planned End Date', field: 'plannedEndDate' },
    { name: 'Labor Rate', field: 'laborRate' },
    { name: 'Total Number of Hours', field: 'totalHours' },
    { name: 'Support Type', field: 'supportTypeId' },
    { name: 'Status', field: 'statusId' },
    { name: 'Service Markup', field: 'serviceMarkup' },
    { name: 'Spend Ceiling ($ Total Value)', field: 'spendCeilingTotal' },
    { name: 'Spend Ceiling ($ Monthly Value)', field: 'spendCeilingMonthly' }
  ]
  const valueOptions = [
    { value: '', label: '-' },
    { value: 'optional', label: 'Optional' },
    { value: 'required', label: 'Required' }
  ]

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 4 } },
          { colspan: { default: 12, xxs: 4 } },
          { colspan: { default: 12, xxs: 4 } }
        ]}
      >
        <RadFormField label='Service' field={`service.${item.id ?? item.uuid}.serviceId`}>
          <RadInput
            placeholder='Enter name'
            ariaRequired
            value={item.name ?? ''}
            onChange={({ detail }) => {
              const services = formValues.services
              item.name = detail.value
              setFormValues({ ...formValues, services })
            }}
          />
        </RadFormField>
        <RadBox padding={{ top: 'xl' }}>
          <RadButton
            onClick={() => {
              const services = formValues.services
                .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, services })
            }}
          >
            Remove service
          </RadButton>
        </RadBox>
      </RadGrid>
      <div>
        {values.map(x =>
          <div style={{ width: '230px', display: 'inline-block', marginRight: '16px', marginBottom: '16px' }} key={x.field}>
            <RadFormField label={x.name}>
              <RadSelect
                filterType='auto'
                selectedOption={valueOptions.find(y => y.value === item[x.field]) ?? { value: null, label: '-' }}
                onChange={({ detail }) => {
                  const services = formValues.services
                  item[x.field] = detail.selectedOption.value
                  setFormValues({ ...formValues, services })
                }}
                options={valueOptions}
                selectedAriaLabel='Selected'
                empty='No matches found'
              />
            </RadFormField>
          </div>
        )}
      </div>
      {item !== formValues.services[formValues.services.length - 1] && <RadDivider />}
    </RadSpaceBetween>
  )
}
