import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { formatCurrency, formatDate } from '../common/utilities'
import { RadCards } from '../common/RadCards'

export function ContractDetail() {
  const navigate = useNavigate()
  const { contractId } = useParams()
  const { data: contract } = useGet(`/api/contract/${contractId}`)
  const { data: serviceLines } = useGet('/api/service-line')
  const remove = useDelete(`/api/contract/${contractId}`, () => { navigate('/contract') })
  const confirmDelete = useConfirm('Delete contract?', 'Delete contract permanently? This action cannot be undone.', 'Delete', () => { remove() })

  function edit(evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (
    contract != null &&
    serviceLines != null
  ) {
    return (
      <RadAppLayout
        name={contract.number || contract.id}
        contentHeader={
          <RadHeader
            variant='h2'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={contract.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={contract.description}
          >
            {contract.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <Details contract={contract} />

            <RadHeader variant='h2'>
              Services
              {contract.services.length === 0 &&
                <RadBox color='text-status-inactive' padding={{ top: 'xxs' }}>No services added to this contract.</RadBox>}
            </RadHeader>
            <RadCards
              cardDefinition={{
                header: item => item.service.serviceLine.name,
                sections: [
                  {
                    id: 'id',
                    header: 'ID',
                    content: item => item.number || item.id
                  },
                  {
                    id: 'service',
                    header: 'Service',
                    content: item => item.service.name
                  },
                  {
                    id: 'values',
                    content: item => {
                      return (
                        <RadSpaceBetween size='m'>
                          {item.projectId != null &&
                            <RadBox variant='awsui-key-label'>
                              Project
                              <RadBox>
                                {item.project && <RadLink href={`/project/${item.project.id}`}>{item.project.name}</RadLink>}
                                {!contract.invoiceContact && <div>-</div>}
                              </RadBox>
                            </RadBox>}
                          {item.chartOfAccountId != null &&
                            <RadBox variant='awsui-key-label'>
                              Chart of Account
                              <RadBox>{item.chartOfAccount.name}</RadBox>
                            </RadBox>}
                          {item.contractTypeId != null &&
                            <RadBox variant='awsui-key-label'>
                              Contract Type
                              <RadBox>{item.contractType.name}</RadBox>
                            </RadBox>}
                          {item.revenueDesignationId != null &&
                            <RadBox variant='awsui-key-label'>
                              Revenue Designation
                              <RadBox>{item.revenueDesignation.name}</RadBox>
                            </RadBox>}
                          {item.totalValue != null &&
                            <RadBox variant='awsui-key-label'>
                              Total Value
                              <RadBox>${item.totalValue}</RadBox>
                            </RadBox>}
                          {item.invoiceApproverId != null &&
                            <RadBox variant='awsui-key-label'>
                              Invoice Approver
                              <RadBox>
                                <RadLink href={`/person/${item.invoiceApproverId}`}>{item.invoiceApprover.fullName}</RadLink>
                              </RadBox>
                            </RadBox>}
                          {item.startDate != null &&
                            <RadBox variant='awsui-key-label'>
                              Start Date
                              <RadBox>{formatDate(item.startDate)}</RadBox>
                            </RadBox>}
                          {item.endDate != null &&
                            <RadBox variant='awsui-key-label'>
                              End Date
                              <RadBox>{formatDate(item.endDate)}</RadBox>
                            </RadBox>}
                          {item.plannedStartDate != null &&
                            <RadBox variant='awsui-key-label'>
                              Planned Start Date
                              <RadBox>{formatDate(item.plannedStartDate)}</RadBox>
                            </RadBox>}
                          {item.plannedEndDate != null &&
                            <RadBox variant='awsui-key-label'>
                              Planned End Date
                              <RadBox>{formatDate(item.plannedEndDate)}</RadBox>
                            </RadBox>}
                          {item.laborRate != null &&
                            <RadBox variant='awsui-key-label'>
                              Labor Rate
                              <RadBox>${item.laborRate}</RadBox>
                            </RadBox>}
                          {item.totalHours != null &&
                            <RadBox variant='awsui-key-label'>
                              Total Hours
                              <RadBox>${item.totalHours}</RadBox>
                            </RadBox>}
                          {item.supportTypeId != null &&
                            <RadBox variant='awsui-key-label'>
                              Support Type
                              <RadBox>{item.supportType.name}</RadBox>
                            </RadBox>}
                          {item.serviceMarkup != null &&
                            <RadBox variant='awsui-key-label'>
                              Service Markup
                              <RadBox>{item.serviceMarkup} %</RadBox>
                            </RadBox>}
                          {item.spendCeilingTotal != null &&
                            <RadBox variant='awsui-key-label'>
                              Spend Ceiling Total
                              <RadBox>${item.spendCeilingTotal}</RadBox>
                            </RadBox>}
                          {item.spendCeilingMonthly != null &&
                            <RadBox variant='awsui-key-label'>
                              Spend Ceiling Monthly
                              <RadBox>${item.spendCeilingMonthly}</RadBox>
                            </RadBox>}
                          {item.statusId != null &&
                            <RadBox variant='awsui-key-label'>
                              Status
                              <RadBox>{item.status.name}</RadBox>
                            </RadBox>}
                          {item.notes != null &&
                            <RadBox variant='awsui-key-label'>
                              Notes
                              <RadBox>{item.notes}</RadBox>
                            </RadBox>}
                          {item.files.length > 0 &&
                            <RadBox variant='awsui-key-label'>
                              Files
                              <RadSpaceBetween size='xs'>
                                {item.files.map(x => <RadLink key={'service-file-' + x.file.id} href={x.file.link} target='_blank' rel='noreferrer'>{x.file.name}</RadLink>)}
                              </RadSpaceBetween>
                            </RadBox>}
                          {item.milestones.length > 0 &&
                            <RadBox variant='awsui-key-label'>
                              Milestones
                              <RadSpaceBetween size='xs'>
                                {item.milestones.map(x => <RadBox key={'service-milestone-' + x.id}>{x.name} | {x.type.name} {x.value != null ? ' - ' + formatCurrency(x.value) : ''}</RadBox>)}
                              </RadSpaceBetween>
                            </RadBox>}
                        </RadSpaceBetween>
                      )
                    }
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={contract.services}
              variant='full-page'
            />

            <RadHeader variant='h2'>
              Change Orders
              {contract.changeOrders.length === 0 &&
                <RadBox color='text-status-inactive' padding={{ top: 'xxs' }}>No change orders added to this contract.</RadBox>}
            </RadHeader>
            <RadCards
              cardDefinition={{
                header: item => formatDate(item.date),
                sections: [
                  {
                    id: 'status',
                    header: 'Status',
                    content: item => item.status?.name
                  },
                  {
                    id: 'type',
                    header: 'Type',
                    content: item => item.type?.name
                  },
                  {
                    id: 'description',
                    header: 'Description',
                    content: item => item.description
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={contract.changeOrders}
              variant='full-page'
            />

            <RadHeader variant='h2'>
              Invoices
              {contract.invoices.length === 0 &&
                <RadBox color='text-status-inactive' padding={{ top: 'xxs' }}>No invoices added to this contract.</RadBox>}
            </RadHeader>
            <RadCards
              cardDefinition={{
                header: item => item.title,
                sections: [
                  {
                    id: 'dateToBeSent',
                    header: 'Date To Be Sent',
                    content: item => formatDate(item.dateToBeSent)
                  },
                  {
                    id: 'status',
                    header: 'Invoice Status',
                    content: item => item.invoiceStatus?.name
                  },
                  {
                    id: 'items',
                    header: 'Items',
                    content: item => item.items.map(x =>
                      <RadBox key={'invoice-item-' + x.id}>{x.milestone.name} | {x.quantity} | {x.description} | {formatCurrency(x.price)}</RadBox>)
                  }
                  // ,
                  // {
                  //   id: 'total',
                  //   header: 'Total',
                  //   content: item => formatCurrency(item.items.reduce((a, b) => a + b.price * b.quantity, 0))
                  // }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={contract.invoices}
              variant='full-page'
            />

          </RadSpaceBetween>
        }
      />
    )
  }
}

function Details({ contract }) {
  function getEncodedLink(item) {
    const path = item.substring(0, item.lastIndexOf('/'))
    const filename = item.substring(item.lastIndexOf('/') + 1)
    return path + '/' + encodeURIComponent(filename)
  }

  return (
    <RadContainer
      header={
        <RadHeader variant='h2'>
          Details
        </RadHeader>
      }
    >
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Number
          </RadBox>
          <div>{contract.number || contract.id}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Name
          </RadBox>
          <div>{contract.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Client
          </RadBox>
          {contract.client && <RadLink href={`/organization/${contract.client.id}`}>{contract.client.commonName}</RadLink>}
          {!contract.client && <div>-</div>}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Funder
          </RadBox>
          {contract.funder && <RadLink href={`/organization/${contract.funder.id}`}>{contract.funder.commonName}</RadLink>}
          {!contract.funder && <div>-</div>}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Invoice Contact
          </RadBox>
          {contract.invoiceContact && <RadLink href={`/person/${contract.invoiceContact.id}`}>{contract.invoiceContact.fullName}</RadLink>}
          {!contract.invoiceContact && <div>-</div>}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Status
          </RadBox>
          <div>{contract.contractStatus?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Contracted Total Value
          </RadBox>
          <div>{formatCurrency(contract.totalValue) ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Amount Invoiced
          </RadBox>
          <div>{formatCurrency(contract.amountInvoiced) ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Executed Date
          </RadBox>
          <div>{formatDate(contract?.executedDate) ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Effective Date
          </RadBox>
          <div>{formatDate(contract?.effectiveDate) ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Expiration Date
          </RadBox>
          <div>{formatDate(contract?.expirationDate) ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Hourly Rate
          </RadBox>
          <div>{contract?.hourlyRate ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Invoicing Terms
          </RadBox>
          <div>{contract?.invoicingTerms ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Payment Terms
          </RadBox>
          <div>{contract?.paymentTerms ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Files
          </RadBox>
          <div>{contract.files.length > 0
            ? <RadSpaceBetween size='xxs'>
              {contract.files.map(x =>
                <RadLink key={'file-' + x.file.id} href={getEncodedLink(x.file.link)} target='_blank'>{x.file.name}</RadLink>)}
            </RadSpaceBetween>
            : '-'}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Notes
          </RadBox>
          <div>{contract?.notes ?? '-'}</div>
        </div>
      </RadColumnLayout>
    </RadContainer>
  )
}
