import React, { useContext } from 'react'
import { AppContext } from './../App'
import FormField from '@cloudscape-design/components/form-field'

// https://cloudscape.design/components/form-field
export function RadFormField ({ field, label, optional, required, screenReader, ...rest }) {
  const { error } = useContext(AppContext)
  const errorText = field != null && error != null && error.validationErrors != null ? error.validationErrors[field] : null

  const classes = []
  if (screenReader) {
    classes.push('screen-reader-only')
  }
  if (required) {
    classes.push('required')
  }

  return (
    <FormField
      errorText={errorText != null ? errorText : null}
      label={<span className={classes.join(' ')}>{label}{optional && <i> - optional </i>}</span>}
      {...{ ...rest }}
    />
  )
}
